import gql from 'graphql-tag'

export const CREATE_CONTROL_POINT_USER_BONUS_MUTATION = gql`
  mutation CreateControlPointUserBonus($bonus: ID!) {
    createControlPointUserBonus(input: { bonus: $bonus }) {
      controlPointUserBonus {
        id
      }
    }
  }
`

export const PATCH_CONTROL_POINT_USER_BONUS_MUTATION = gql`
  mutation PatchControlPointUserBonus(
    $id: ID!
    $input: PatchControlPointUserBonusInput!
  ) {
    patchControlPointUserBonus(id: $id, input: $input) {
      controlPointUserBonus {
        id
      }
    }
  }
`

export const CREATE_CONTROL_POINT_VISIT_MUTATIONS = gql`
  mutation CreateControlPointVisit($input: CreateControlPointVisitInput!) {
    createControlPointVisit(input: $input) {
      controlPointVisit {
        id
        visitedAt
        controlPoint {
          id
        }
      }
    }
  }
`

export const CREATE_CONTROL_POINT_VISIT_BY_QR_MUTATION = gql`
  mutation CreateControlPointVisitByQr($qrCode: String!) {
    createControlPointVisitByQr(qrCode: $qrCode) {
      found
      loginRequired
      alreadyVisited
      eventShortName
      controlPointId
    }
  }
`
