function degToRad(degrees) {
  return degrees * Math.PI / 180
}

export function distance(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371

  var dLat = degToRad(lat2-lat1)
  var dLon = degToRad(lon2-lon1)

  lat1 = degToRad(lat1)
  lat2 = degToRad(lat2)

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

  return earthRadiusKm * c
}
