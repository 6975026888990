import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useIntl as intl } from 'react-intl'
import { __RouterContext as RouterContext } from 'react-router'
import { UserContext, EventContext } from 'context'
import messages from 'locale/messages'

export function useDebounce(value, delay) {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value)
    }, delay)

    return () => clearTimeout(timeout)
  }, [delay, value])

  return debounced
}

const en = messages['en']
function fm(id, defaultMessage = null, values = null) {
  if (!defaultMessage) defaultMessage = en[id]
  return this.formatMessage(
    {
      id,
      defaultMessage,
    },
    values || undefined
  )
}
export const useIntl = () => {
  const _intl = intl()
  _intl.fm = fm.bind(_intl)
  return _intl
}

export const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey])

  return keyPressed
}

// Hook
export function useEventListener(eventName, handler, element = window) {
  const handlerRef = useRef()

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = evt => handlerRef.current(evt)
    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export function useDoubleTap(handler, element = window, delay = 350) {
  const handlerRef = useRef()
  const [prevTap, setPrevTap] = useState(0)
  const [tapEvt, setTapEvt] = useState(null)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const now = Date.now()
    if (now - prevTap < delay) handlerRef.current(tapEvt)
    setPrevTap(now)
  }, [delay, prevTap, tapEvt])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    element.addEventListener('touchend', setTapEvt)

    return () => {
      element.removeEventListener('touchend', setTapEvt)
    }
  }, [element])
}

const constructMediaQueries = (breakpoints, onlyScreen) => {
  return breakpoints.reduce((queries, breakpoint, index) => {
    queries.push(`
      ${onlyScreen ? 'only screen and ' : ''}
      ${
        !index
          ? '(min-width: 0px) and '
          : `(min-width: ${breakpoints[index - 1]}) and `
      }
      (max-width: ${breakpoint - 1}px)
    `)
    if (index === breakpoints.length - 1)
      queries.push(`
        ${onlyScreen ? 'only screen and ' : ''}
        (min-width: ${breakpoint}px)
      `)
    return queries.map(q => q.replace(/[\s]{2,}/g, ' ').trim())
  }, [])
}
const getMediaMatches = queries =>
  queries.map(mqr => window.matchMedia(mqr)).map(m => m.matches)
const DEFAULT_MEDIA_BREAKPOINTS = [768]
export function useMedia(
  breakpoints = DEFAULT_MEDIA_BREAKPOINTS,
  onlyScreen = true
) {
  const mediaQueries = constructMediaQueries(breakpoints, onlyScreen)

  const [matches, setMatches] = useState(getMediaMatches(mediaQueries))

  useEffect(() => {
    const resizeHandler = () => setMatches(getMediaMatches(mediaQueries))
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [mediaQueries])

  return matches
}

export const useRouter = () => {
  return useContext(RouterContext)
}

export const useUser = () => useContext(UserContext)
export const useEvent = () => useContext(EventContext)

export function useSound(soundFile) {
  const play = useCallback(() => {
    const audio = new Audio(soundFile)
    audio.play()
  }, [soundFile])

  return play
}
