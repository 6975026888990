import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'
import { frmt } from 'util/helpers'
import { cssSize } from 'util/prop-type-validators'

import FlexCenter from 'components/FlexCenter'
import Icon from 'components/Icon'
import Margin from 'components/Margin'

function CenteredErrorMessage({
  className,

  intlId,
  defaultMessage,
  message,
  icon,
  marginTop
}) {
  const intl = useIntl()
  const _message = intlId
    ? frmt(intlId, defaultMessage, intl)
    : (message || frmt('server.general-error-update', 'Server error. Please try refreshing the page.', intl))
  const _icon = typeof icon === 'string' ? <Icon icon={icon} color="error" /> : icon

  return (
    <FlexCenter horizontal className={className}>
      <Margin top={marginTop}>
        <Margin inline right="6px">
          {_icon}
        </Margin>
        {_message}
      </Margin>
    </FlexCenter>
  )
}

CenteredErrorMessage.propTypes = {
  intlId: PropTypes.string,
  defaultMessage: PropTypes.string,

  message: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Icon)]),
  marginTop: cssSize
}
CenteredErrorMessage.defaultProps = {
  icon: 'exclamation-triangle',
  marginTop: '6em'
}

export default CenteredErrorMessage
