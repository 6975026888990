import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { cssSize } from 'util/prop-type-validators'

import Loader from 'components/Loader'
import Margin from 'components/Margin'

const Wrapper = styled(Margin)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  ${props => props.text && `
    &::after {
      content: '${props.text}';
      font-size: ${props.fontSize};

      position: absolute;
      left: 50%;
      bottom: -4rem;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  `}
`

function CenteredLoader({
  size,
  marginTop,
  text,
  fontSize
}) {
  return (
    <Wrapper top={marginTop} text={text} fontSize={fontSize}>
      <Loader size={size} />
    </Wrapper>
  )
}

CenteredLoader.propTypes = {
  size: PropTypes.number,
  marginTop: cssSize,
  text: PropTypes.string,
  fontSize: cssSize,
}
CenteredLoader.defaultProps = {
  size: 32,
  marginTop: '2rem',
}

export default CenteredLoader
