import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_CONTROL_POINT_VISIT_BY_QR_MUTATION } from './mutations'
import FullLoader from 'components/Loader/FullLoader'
import { toasts } from 'store'
import { useIntl } from 'util/hooks'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.ebb};
`

function ParseQRCode() {
  const history = useHistory()
  const match = useRouteMatch()
  const qrCode = match.params.qrCode

  const intl = useIntl()
  const translations = {
    qrCodeNotFound: intl.fm('map.qr-code-not-found'),
    loginFirst: intl.fm('map.qr-code-found-login-first'),
    checking: intl.fm('map.checking-qr-code'),
    error: intl.fm('errors.something-went-wrong'),
    back: intl.fm('common.back'),
  }

  const [createVisit, { error }] = useMutation(
    CREATE_CONTROL_POINT_VISIT_BY_QR_MUTATION,
    {
      onCompleted({
        createControlPointVisitByQr: {
          found,
          loginRequired,
          alreadyVisited,
          eventShortName,
          controlPointId,
        },
      }) {
        if (!found) {
          toasts.addToast('warning', translations.qrCodeNotFound)
          history.push(loginRequired ? '/login' : '/select-event')
          return
        }
        if (!loginRequired) {
          window.location.href = `/e/${eventShortName}/map?point=${controlPointId}&visited=${
            alreadyVisited ? 'true' : 'false'
          }`
        } else {
          toasts.addToast('info', translations.loginFirst)
          history.push('/login', { next: `/qr/${qrCode}` })
        }
      },
      onError(err) {
        console.error(err)
      },
    }
  )
  useEffect(() => {
    if (!qrCode) return

    createVisit({
      variables: {
        qrCode,
      },
    })
    //eslint-disable-next-line
  }, [])

  if (!qrCode || error)
    return (
      <Wrapper>
        {translations.error}
        <br />
        <a href="/">{translations.back}</a>
      </Wrapper>
    )

  return (
    <Wrapper>
      <FullLoader text={translations.checking + '...'} />
    </Wrapper>
  )
}

export default ParseQRCode
