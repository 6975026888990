import gql from 'graphql-tag'

export const CREATE_EVENT_SIGNUP_MUTATION = gql`
  mutation CreateEventSignup(
    $event: ID!
  ) {
    createEventSignup(
      input: {
        event: $event
      }
    ) {
      eventSignup {
        event {
          id
        }
        user {
          id
        }
        signedUpAt
      }
    }
  }
`
