export default {
  colors: {
    killarney: '#2C5234',       // rgba(44, 82, 52, 1)
    apple: '#6a963b',
    chelseacucumber: '#8FA54F', // rgba(143, 165, 79, 1)
    greensmoke: '#a7b775',
    pineglade: '#c0cb9c',

    seabuckthorn: '#F68D2E',
    creamcan: '#F3D54E',
    cornflower: '#9BCBEB',
    pinecone: '#6E6259',        // rgba(110, 98, 89, 1)
    americano: '#7E726D',       // rgba(126, 114, 109, 1)

    ebb: '#E9E6E4',             // rgba(233, 230, 228, 1)
    desertstorm: '#F4F3F2',     // rgba(244, 243, 242, 1)

    primary: '#8FA54F',
    secondary: '#9BCBEB',
    success: '#6a963b',
    info: '#9BCBEB',
    warning: '#F3D54E',
    error: '#c41a14',

    defaultBackground: '#E9E6E4',
  }
}