export const LOGIN_TOKEN_KEY = 'login-token'
export const REMEMBER_ME_KEY = 'remember-me'
export const FIRST_LOGIN_KEY = 'first-login'

export function setLoginToken(token, remember = false) {
  localStorage.setItem(LOGIN_TOKEN_KEY, token)

  if (remember) sessionStorage.setItem(REMEMBER_ME_KEY, 'true')
  else {
    sessionStorage.setItem(REMEMBER_ME_KEY, 'false')
  }
}

export function hasSavedLoginToken() {
  return !!localStorage.getItem(LOGIN_TOKEN_KEY)
}

export function getLoginToken() {
  return localStorage.getItem(LOGIN_TOKEN_KEY)
}

export function removeLoginToken() {
  return localStorage.removeItem(LOGIN_TOKEN_KEY)
}

export function getRememberMe() {
  return sessionStorage.getItem(REMEMBER_ME_KEY) === 'true'
}

export function setFirstLogin() {
  localStorage.setItem(FIRST_LOGIN_KEY, 'true')
}
export function getFirstLogin() {
  return localStorage.getItem(FIRST_LOGIN_KEY) === 'true'
}
export function removeFirstLogin() {
  return localStorage.removeItem(FIRST_LOGIN_KEY)
}
