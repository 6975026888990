import styled from 'styled-components'

export default styled.div`
  display: ${props => props.inline ? "inline-block" : "block"};

  ${props => props.borderBox ? 'box-sizing: border-box;' : ''}

  ${props => props.all ? `
    margin-top: ${props.all};
    margin-bottom: ${props.all};
    margin-left: ${props.all};
    margin-right: ${props.all};
  ` : ""}

  ${props => props.top ? `margin-top: ${props.top};` : ""}
  ${props => props.bottom ? `margin-bottom: ${props.bottom};` : ""}
  ${props => props.left ? `margin-left: ${props.left};` : ""}
  ${props => props.right ? `margin-right: ${props.right};` : ""}
  ${props => props.width ? `width: ${props.width};` : ""}
`
