import { css } from 'styled-components'
import theme from 'theme'

/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param {Object} props The props passed to a component.
 */
export function overloadColorProp(props) {
  return props.color in props.theme.colors ?
    props.theme.colors[props.color] : props.color
}

export function overloadColor(color) {
  return overloadColorProp({ color, theme })
}

function validateHex(hex) {
  if (!hex) return null
  if (hex.charAt(0) === '#') hex = hex.substring(1)
  if (hex.length !== 3 && hex.length !== 6) {
    return null
  }

  if (hex.length === 3)
    hex = hex.split('').reduce((acc, cur) => acc + cur + cur, '')

  if (!/^([a-f0-9]{2}){3}$/.test(hex)) {
    return null
  }

  return hex
}

export function hexWithAlpha(hex, alpha) {
  hex = hex.replace(/^#?([0-9a-f]{6})$/i, '$1')
  hex = Number(`0x${hex}`)

  const red = (hex >> 16) & 0xff
  const green = (hex >> 8) & 0xff
  const blue = hex & 0xff

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export function rgbFromHex(hex) {
  hex = validateHex(hex)

  const red = parseInt(hex.substring(0, 2), 16)
  const green = parseInt(hex.substring(2, 4), 16)
  const blue = parseInt(hex.substring(4, 6), 16)

  return [red, green, blue]
}

export const buttonUnset = css`
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: inherit;
  appearance: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
`