import React, { useRef, useState, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ResizeObserver from 'react-resize-observer'

const Text = styled.div`
  box-sizing: border-box;

  white-space: nowrap;
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};

  div.tester {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
  }
`

function FitText({ text, className, fontWeight, maxSize }) {
  const textRef = useRef(null)
  const testerRef = useRef(null)

  const [fontSize, setFontSize] = useState(16)

  const measure = useCallback((size, containerWidth) => {
    if (!testerRef.current) return true

    const el = testerRef.current
    el.style.fontSize = size + 'px'
    const width = el.clientWidth + 1

    return width <= containerWidth
  }, [])

  const handleResize = useCallback(
    ({ width }) => {
      let newSize = fontSize
      for (let size = maxSize; size--; ) {
        if (measure(size, width)) {
          newSize = size
          break
        }
      }
      setFontSize(newSize)
    },
    [fontSize, maxSize, measure]
  )

  useLayoutEffect(() => {
    if (!textRef.current) return
    handleResize({ width: textRef.current.offsetWidth })
  }, [text, handleResize])

  return (
    <Text
      ref={textRef}
      className={className}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      <ResizeObserver onResize={handleResize} />
      <div ref={testerRef} className="tester">
        {text}
      </div>
      {text}
    </Text>
  )
}

FitText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  fontWeight: PropTypes.string,
  maxSize: PropTypes.number,
}
FitText.defaultProps = {
  fontWeight: 'normal',
  maxSize: 48,
}

export default FitText
