import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FormattedMessage } from 'react-intl'

import iPhoneShareIcon from 'assets/images/iphone-share-icon.png'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  padding: 1rem;

  h1 {
    margin: 0 0 1rem;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.pinecone};
  }
  h2 {
    margin: 1rem 0 0.2rem;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.killarney};
    text-transform: uppercase;
  }
  h3 {
    margin: 0.5rem 0 0.2rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: ${props => props.theme.colors.killarney};
  }
  p {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
`
const ExpandSection = styled.section`
  margin-top: 1rem;

  border: 1px solid ${props => props.theme.colors.pineglade};
  background-color: ${props => props.theme.colors.desertstorm};

  h3 {
    position: relative;
    margin: 0;
    padding: 6px;
    background-color: ${props => props.theme.colors.pineglade};
    color: ${props => props.theme.colors.desertstorm};

    &::after {
      position: absolute;
      content: ${props => props.open ? "'\f077'" : "'\f078'"};
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      font-family: 'Font Awesome 5 Pro';
    }
  }
  div {
    box-sizing: border-box;
    padding: 6px;

    ol {
      margin: 0;
      padding: 0.5rem 1.5rem;

      li {
        img {
          max-height: 2ex;
          margin: 0 0.5ch;
        }
        i {
          margin: 0 0.5ch;
        }
      }
    }
  }
`
const IosEm = styled.em`
  color: #147efb;
  font-style: normal;
  font-weight: 900;
`
const AndroidEm = styled.em`
  color: #020930;
  font-style: normal;
  font-weight: 900;
`
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

function Help() {
  const [sectionOpen, setSectionOpen] = useState({})

  useEffect(() => {
    const operatingSystem = getMobileOperatingSystem()
    if (operatingSystem === 'iOS') {
      setSectionOpen({
        hs_ios: true,
        geo_ios: true
      })
    } else if (operatingSystem === 'Android') {
      setSectionOpen({
        hs_android: true,
        geo_android: true
      })
    }
  }, [])

  const toggleOpen = key => {
    setSectionOpen({
      ...sectionOpen,
      [key]: !sectionOpen[key]
    })
  }

  return (
    <Wrapper>
      <h1>
        <FormattedMessage id="common.help" defauleMessage="Help" />
      </h1>

      <p>Support: cultura@cultura.no</p>
      <h2>Legg til på hjemskjerm</h2>
      <p>
        Det lønner seg å legge til denne appen på hjemskjermen din. Det vil gi deg rask tilgang og en generelt bedre opplevelse; nettsiden vil da oppføre seg som en vanlig applikasjon. Under ser du informasjon om hvordan du kan gjøre dette om du er usikker.
      </p>

      <ExpandSection open={sectionOpen.hs_ios}>
        <h3 onClick={() => toggleOpen('hs_ios')}>iPhone</h3>
        {sectionOpen.hs_ios && (
          <div>
            <ol>
              <li>Åpne denne nettsiden i Safari.</li>
              <li>Trykk på <img src={iPhoneShareIcon} alt="iphone-share-icon" /> nederst på skjermen.</li>
              <li>Trykk på ikonet hvor det står <IosEm>Add to Home Screen</IosEm>.</li>
              <li>I dialogen som spretter opp, trykk på <IosEm>Add</IosEm>.</li>
              <li>Appen vil nå ligge på hjem-skjermen din.</li>
            </ol>
          </div>
        )}
      </ExpandSection>

      <ExpandSection open={sectionOpen.hs_android}>
        <h3 onClick={() => toggleOpen('hs_android')}>Android</h3>
        {sectionOpen.hs_android && (
          <div>
            <ol>
              <li>Åpne denne nettsiden i Chrome.</li>
              <li>Trykk på <Icon icon="ellipsis-v" color="black" /> øverst i høyre hjørne på skjermen.</li>
              <li>Trykk på <AndroidEm>Legg til på startsiden</AndroidEm>.</li>
              <li>Trykk på <AndroidEm>Legg til</AndroidEm>.</li>
              <li>Trykk på <AndroidEm>Legg til</AndroidEm> igjen.</li>
              <li>Appen vil nå ligge på startsiden din.</li>
            </ol>
          </div>
        )}
      </ExpandSection>

      <h2>Geolokasjon</h2>
      <p>
        Denne appen er avhengig av at du deler dine koordinater for at vi skal kunne se om du har besøkt steder. Din lokasjon blir ikke lagret, så klart med unntak av besøkene.
      </p>
      <p>
        I de fleste tilfeller vil det komme opp en dialog når du går inn på kartet som spør om lov til å bruke lokasjonen din. I noen tilfeller vil dette dog ikke skje, og du kan da prøve å følge denne guiden:
      </p>

      <ExpandSection open={sectionOpen.geo_ios}>
        <h3 onClick={() => toggleOpen('geo_ios')}>iPhone</h3>
        {sectionOpen.geo_ios && (
          <div>
            <ol>
              <li>Gå inn på <IosEm>Innstillinger</IosEm> på telefonen din.</li>
              <li>Velg <IosEm>Personvern</IosEm>.</li>
              <li>Velg <IosEm>Stedstjenester</IosEm>.</li>
              <li>Finn <IosEm>Safari</IosEm> i menyen og trykk på den.</li>
              <li>Velg <IosEm>«Når appen er i bruk»</IosEm> eller <IosEm>«Alltid»</IosEm>.</li>
            </ol>
          </div>
        )}
      </ExpandSection>

      <ExpandSection open={sectionOpen.geo_android}>
        <h3 onClick={() => toggleOpen('geo_android')}>Android</h3>
        {sectionOpen.geo_android && (
          <div>
            <ol>
              <li>Gå inn på <AndroidEm>Innstillinger</AndroidEm> på telefonen din.</li>
              <li>Trykk på <AndroidEm>Apper og varsler</AndroidEm>.</li>
              <li>Finn <AndroidEm>Chrome</AndroidEm> i menyen og trykk på den.</li>
              <li>Trykk på <AndroidEm>Rettigheter</AndroidEm>.</li>
              <li>Gi tilgang til <AndroidEm>Posisjon</AndroidEm> ved å trykke på den om den er grå.</li>
            </ol>
          </div>
        )}
      </ExpandSection>
    </Wrapper>
  )
}

export default Help
