import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useIntl } from 'util/hooks'
import { overloadColorProp } from 'util/style'
import { cssSize } from 'util/prop-type-validators'
import Icon from 'components/Icon'

const IconWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  width: ${props => props.size};
  height: 100%;

  top: 0;
  ${props =>
    props.position === 'left'
      ? 'left: 0;'
      : 'right: 0;'
  }

  justify-content: center;
  align-items: center;

  background-color: ${props => props.background};
`

export function Button({
  className,
  children,
  type,

  icon,
  height,
  iconBackground,
  iconPosition,
  iconColor,

  role,
  disabled,

  isLoading,
  loadingText,
  loadingIntlId,

  onClick,

  ...rest
}) {

  const ellipsisTimeout = useRef(null)
  const [ellipsis, setEllipsis] = useState('.')

  useEffect(() => {
    if (isLoading) {
      ellipsisTimeout.current = setTimeout(() => {
        setEllipsis((ellipsis.length < 3) ? (ellipsis + '.') : '.')
      }, 350)
    } else {
      clearTimeout(ellipsisTimeout.current)
    }

    return () => {
      clearTimeout(ellipsisTimeout.current)
    }
  }, [ellipsis, isLoading])

  const iconComp = typeof icon === 'string'
    ? <Icon light icon={icon} color={disabled ? 'gray' : iconColor} size="1rem" />
    : icon

  const aria = Object.entries(rest)
    .reduce((_aria, [attr, val]) =>
      attr.startsWith('aria-') ? ({ ..._aria, [attr]: val }) : _aria
    , {})

  const intl = useIntl()
  const _loadingText = loadingText || intl.fm(loadingIntlId)

  return (
    <button className={className} type={type} onClick={onClick} disabled={disabled} role={role} {...aria}>
      {icon && iconPosition === 'left' ?
        <IconWrapper size={height} background={iconBackground} position={iconPosition}>
          {iconComp}
        </IconWrapper> : null
      }

      {isLoading ? (
        <>
          {_loadingText}
          {ellipsis}
        </>
      ) : (
        <>{children}</>
      )}

      {icon && iconPosition === 'right' ?
        <IconWrapper size={height} background={iconBackground} position={iconPosition}>
          {iconComp}
        </IconWrapper> : null
      }
    </button>
  )
}

const getBackgroundColor = props => {
  if (props.disabled) return 'disabled'
  if (props.primary) return 'primary'
  if (props.cancel) return 'cancel'
  if (props.save) return 'save'
  if (props.success) return 'success'
  return props.backgroundColor
}

const getForegroundColor = props => {
  if (props.disabled) return '#555'
  if (props.primary) return 'white'
  if (props.cancel) return 'white'
  if (props.save) return 'white'
  if (props.success) return 'white'
  return props.color
}

const StyledButton = styled(Button)`
  position: relative;

  width: ${props => props.fullWidth ? '100%' : props.width};
  height: ${props => props.height};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: ${props => overloadColorProp({
      theme: props.theme,
      color: getBackgroundColor(props)
    }) || 'white'
  };
  color: ${props => overloadColorProp({
      theme: props.theme,
      color: getForegroundColor(props)
    }) || 'white'
  };

  text-transform: uppercase;
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};

  ${props =>
    props.icon
      ? (
        props.iconPosition === 'left'
          ? `padding: 0 1.4em 0 calc(${props.height} + 1.4em);`
          : `padding: 0 calc(${props.height} + 1.4em) 0 1.4em;`
      )
      : 'padding: 0 1.4em;'
  }

  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  overflow: hidden;
  user-select: none;
  box-sizing: border-box;

  outline: 2px solid ${props => overloadColorProp({
      theme: props.theme,
      color: getBackgroundColor(props)
    }) || 'white'};
  outline-offset: -2px;
  transition: outline-offset 100ms ease-out;
  &:active, &:focus {
    outline-offset: 2px;
  }
`

StyledButton.propTypes = {
  type: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,

  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,

  fontSize: cssSize,
  fontWeight: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconBackground: PropTypes.string,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,

  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingIntlId: PropTypes.string,

  role: PropTypes.string,
  disabled: PropTypes.bool
}

StyledButton.defaultProps = {
  type: 'submit',
  primary: false,
  secondary: false,

  fullWidth: false,
  width: 'auto',
  height: '36px',

  fontSize: '0.8rem',
  fontWeight: 400,
  color: 'gray',

  icon: null,
  iconPosition: 'left',
  iconBackground: 'inherit',
  iconColor: 'rgba(255, 255, 255, 0.3)',

  isLoading: false,
  loadingText: null,
  loadingIntlId: 'common.loading',

  disabled: false
}

export default StyledButton
