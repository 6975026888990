import gql from 'graphql-tag'

export const UserFragments = {
  info: gql`
    fragment UserInfo on UserNode {
      id
      username
      email
      phoneNumber
      firstName
      lastName
      fullName
      hasRegistered
      needsPasswordChange
      createdAt
      updatedAt
      isStaff
      isSuperuser
      isActive
      bonusesReceived {
        edges {
          node {
            id
            name
            description
            image
            controlPoints {
              edges {
                node {
                  id
                }
              }
            }
            validFrom
            validUntil
            userBonuses {
              edges {
                node {
                  id
                  user { id }
                  usedAt
                }
              }
            }
          }
        }
      }
    }
  `
}