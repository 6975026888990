import Input from './Input'
import FlatInput from './FlatInput'
import NumberInput from './NumberInput'

export {
  Input,
  FlatInput,
  NumberInput,
}
export default Input
