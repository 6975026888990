import { DEBUG } from 'util/env'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/react-hooks'
import { PATCH_CONTROL_POINT_USER_BONUS_MUTATION } from './mutations'
import moment from 'moment'
import { useIntl, useSound } from 'util/hooks'
import { toasts } from 'store'

import Icon, { CulturaIcon } from 'components/Icon'
import { FullLoader } from 'components/Loader'
import ConfirmCoupon from 'assets/sounds/confirmCoupon.wav'

import {
  Wrapper,
  Cards,
  BonusCard,
  Cover,
  Image,
  UseButton,
  Backface,
  ConfirmUse,
} from './components/ControlPointBonusList'
import FitText from 'components/FitText/index'

function ControlPointBonusList({ controlPointBonuses }) {
  const playConfirmation = useSound(ConfirmCoupon)

  const [flippedCards, setFlippedCards] = useState([])
  const [confirmUse, setConfirmUse] = useState(false)

  const [
    patchUserBonusMutation,
    { loading: patchUserBonusLoading },
  ] = useMutation(PATCH_CONTROL_POINT_USER_BONUS_MUTATION, {
    refetchQueries: ['Me', 'CurrentEvent'],
    awaitRefetchQueries: true,
    onCompleted() {
      toasts.addToast('success', translations.confirmSuccess)
      playConfirmation()
    },
    onError(err) {
      if (DEBUG) console.error(err)
      toasts.addToast('error', translations.confirmError)
    },
  })

  const intl = useIntl()
  const translations = {
    expired: intl.fm('points.expired'),
    used: intl.fm('points.used'),
    validFrom: intl.fm('points.valid-from'),
    validUntil: intl.fm('points.valid-until'),
    readMore: intl.fm('common.read-more'),
    visitToActivate: intl.fm('points.visit-to-activate'),
    confirmUse: intl.fm('points.confirm-use'),
    confirm: intl.fm('common.confirm'),
    cancel: intl.fm('common.cancel'),
    confirmSuccess: intl.fm('points.confirm-success'),
    confirmError: intl.fm('points.confirm-error'),
  }

  const isActive = bonus => {
    if (!bonus.userBonus) {
      return [
        false,
        {
          text: translations.visitToActivate,
          color: 'secondary',
        },
      ]
    }
    if (bonus.userBonus.usedAt) {
      return [
        false,
        {
          text: translations.used,
          color: 'error',
        },
      ]
    }

    const now = moment()
    const from = bonus.validFrom
      ? moment(bonus.validFrom)
      : moment(now).subtract(1, 'days')
    const until = bonus.validUntil
      ? moment(bonus.validUntil)
      : moment(now).add(1, 'days')

    let overlay = null

    if (now.diff(from) < 0) {
      overlay = {
        text: intl.fm('points.valid-in', null, { delta: from.fromNow() }),
        color: 'primary',
      }
    } else if (now.diff(until) > 0) {
      overlay = {
        text: translations.expired,
        color: 'error',
      }
    }

    return [now.diff(from) > 0 && now.diff(until) < 0, overlay]
  }
  const unflip = (evt, _id) => {
    if (evt) evt.stopPropagation()
    setFlippedCards(flippedCards.filter(_card => _card !== _id))
  }
  const handleUseClick = bonus => {
    setConfirmUse(true)
    setFlippedCards([...flippedCards, bonus.id])
  }
  const cancelUseBonus = bonus => {
    unflip(null, bonus.id)
    setTimeout(() => setConfirmUse(false), 500)
  }
  const doUseBonus = async bonus => {
    if (!isActive(bonus)) return

    await patchUserBonusMutation({
      variables: {
        id: bonus.userBonus.id,
        input: {
          usedAt: new Date(),
        },
      },
    })

    cancelUseBonus(bonus)
  }

  const _now = moment()
  const controlPointBonusesSorted = controlPointBonuses.sort((a, b) => {
    if (!a.userBonus || a.userBonus.usedAt) return 1
    return (
      _now.diff(
        b.validUntil ? moment(b.validUntil) : moment(_now).add(100, 'years')
      ) -
      _now.diff(
        a.validUntil ? moment(a.validUntil) : moment(_now).add(100, 'years')
      )
    )
  })

  return (
    <Wrapper>
      {patchUserBonusLoading && <FullLoader overlayOpacity={0.5} />}
      <Cards>
        {controlPointBonusesSorted.map(bonus => {
          const [_active, _overlay] = isActive(bonus)

          return (
            <BonusCard
              key={bonus.id}
              pose={flippedCards.includes(bonus.id) ? 'flipped' : 'unflipped'}
              isActive={_active}
              overlay={_overlay}
            >
              <Cover isActive={_active}>
                <Image noImage={!bonus.image}>
                  {bonus.image ? (
                    <img src={bonus.image} alt="Not found" />
                  ) : (
                    <CulturaIcon icon="percent" size="52px" />
                  )}
                </Image>
                <FitText className="name" text={bonus.name} maxSize={16} />
                <dl>
                  {bonus.validFrom && (
                    <>
                      <dt>{translations.validFrom}:</dt>
                      <dd>{moment(bonus.validFrom).format('L LT')}</dd>
                    </>
                  )}
                  {bonus.validUntil && (
                    <>
                      <dt>{translations.validUntil}:</dt>
                      <dd>{moment(bonus.validUntil).format('L LT')}</dd>
                    </>
                  )}
                </dl>
                <div className="more">
                  <button
                    onClick={() =>
                      _active
                        ? setFlippedCards([...flippedCards, bonus.id])
                        : void 0
                    }
                  >
                    {translations.readMore}
                  </button>
                </div>
                <UseButton
                  isActive={_active}
                  onClick={() => (_active ? handleUseClick(bonus) : null)}
                >
                  {_active ? (
                    <CulturaIcon
                      icon="check"
                      background="transparent"
                      size="1.2rem"
                    />
                  ) : (
                    <Icon icon="times" color="white" size="1.2rem" />
                  )}
                </UseButton>
              </Cover>
              {confirmUse ? (
                <ConfirmUse>
                  <p>{translations.confirmUse}</p>
                  <button
                    className="cancel"
                    onClick={() => cancelUseBonus(bonus)}
                  >
                    {translations.cancel}
                  </button>
                  <button className="confirm" onClick={() => doUseBonus(bonus)}>
                    {translations.confirm}
                  </button>
                </ConfirmUse>
              ) : (
                <Backface onClick={evt => unflip(evt, bonus.id)}>
                  <h2>{bonus.name}</h2>
                  <p>{bonus.description}</p>
                </Backface>
              )}
            </BonusCard>
          )
        })}
      </Cards>
    </Wrapper>
  )
}

ControlPointBonusList.propTypes = {
  controlPointBonuses: PropTypes.arrayOf(PropTypes.object).isRequired,
}
ControlPointBonusList.defaultProps = {}

export default ControlPointBonusList
