import { DEBUG } from 'util/env'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose'
import { Link, useHistory } from 'react-router-dom'

import client from 'apollo'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_USER_MUTATION } from 'modules/users/mutations'
import { LOGIN_MUTATION } from './mutations'
import { useIntl } from 'util/hooks'
import { toasts } from 'store'
import { FormattedMessage } from 'react-intl'

import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'
import PasswordStrength from 'components/PasswordStrength'
import { passwordStrength } from 'util/helpers'
import { setLoginToken, setFirstLogin } from './util'
import { IS_LOGGED_IN_QUERY } from './queries'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  height: 100vh;
  padding: 1.6rem;

  a.login {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    color: ${props => props.theme.colors.killarney};

    span {
      margin-right: 1ch;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }

  h1 {
    margin: 0;
    font-size: 1.6rem;
  }
  p {
    margin: 1rem 0;
    font-size: 0.8rem;

    &.required {
      span {
        color: ${props => props.theme.colors.error};
      }
    }
  }
  label.checkbox-label {
    font-size: 0.8rem;

    input[type='checkbox'] {
      visibility: hidden;
      margin-right: 12px;

      &::before {
        content: '';
        visibility: visible;
        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        width: 20px;
        height: 20px;
        transform: translateY(-2px);
        padding-top: 2px;

        font-family: 'Font Awesome 5 Pro';

        background-color: ${props => props.theme.colors.desertstorm};
        border: 1px solid ${props => props.theme.colors.americano};
        border-radius: 4px;
      }
      &:checked::before {
        content: '\f00c';
      }
    }
  }
`
const CreateWrapper = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
})
const SuccessWrapper = posed(styled.div`
  p.redirect {
    font-size: 1rem;
  }
`)({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
})

const FormWrapper = styled.div``
const FormField = styled.div`
  position: relative;

  &:not(:first-of-type) {
    margin-top: ${props => (props.tall ? '1.8rem' : '0.8rem')};
  }
  ${props =>
    props.tall &&
    !props.noDivider &&
    `
    &::before {
      content: '';
      position: absolute;
      top: -0.9rem;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 1px;
      background-color: ${props.theme.colors.primary};
    }
  `}

  &.password-info {
    font-size: 0.8rem;
    text-align: center;
  }
  &.back {
    text-align: center;
    span {
      color: ${props => props.theme.colors.killarney};
    }
  }
`
const InvalidToastInner = styled.ul`
  padding: 0 0 0 1rem;
  font-size: 0.8rem;
`

function Register() {
  const redirectTimerRef = useRef(-1)
  const requiredRef = useRef(null)
  useEffect(() => {
    const el = requiredRef.current
    if (el) {
      const replaced = el.innerText.replace(/\*/g, '<span>*</span>')
      el.innerHTML = replaced
    }
  }, [requiredRef])

  const history = useHistory()

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [newsLetter, setNewsLetter] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [stage, setStage] = useState('info')
  const [userCreateSuccess, setUserCreateSuccess] = useState(false)
  const [redirectSeconds, setRedirectSeconds] = useState(5)

  const [errors, setErrors] = useState({})

  const intl = useIntl()
  const translations = {
    email: intl.fm('common.email'),
    username: intl.fm('users.username'),
    firstName: intl.fm('users.first-name'),
    lastName: intl.fm('users.last-name'),
    phoneNumber: intl.fm('users.phone-number'),

    password: intl.fm('common.password'),
    passwordConfirm: intl.fm('common.password-confirm'),
    passwordWeak: intl.fm('common.password-weak'),
    passwordMedium: intl.fm('common.password-medium'),
    passwordStrong: intl.fm('common.password-strong'),
    passwordsNonIdentical: intl.fm('common.passwords-not-identical'),
    passwordInfo: intl.fm('users.password-info'),

    invalid: intl.fm('common.invalid'),
    errorEmail: intl.fm('errors.invalid-email'),
    errorFirstName: intl.fm('errors.invalid-first-name'),
    errorLastName: intl.fm('errors.invalid-last-name'),
    errorPassword: intl.fm('errors.invalid-password'),
    errorPasswordConfirm: intl.fm('errors.invalid-password-confirm'),

    signIn: intl.fm('login.sign-in'),
    signingIn: intl.fm('login.signing-in'),
    redirecting: n => intl.fm('users.redirecting-in-n', null, { n }),
    setPassword: intl.fm('login.set-password'),
    createUser: intl.fm('users.create-user'),
    fieldsRequired: intl.fm('common.fields-marked-are-required'),
    userCreateError: intl.fm('users.user-create-error'),
    userCreated: intl.fm('users.user-created'),
    userCreatedText: _email =>
      intl.fm('users.user-created-text', null, { email: _email }),
    goToLogin: intl.fm('users.go-to-login'),
    pleaseAcceptTerms: intl.fm('login.please-accept-terms'),
    back: intl.fm('common.back'),

    emailInUse: intl.fm('users.email-in-use'),
    emailInUseShort: intl.fm('users.email-in-use-short'),
    emailInvalid: intl.fm('users.email-invalid'),
    serverError: intl.fm('server.general-error'),
  }

  const [createUserMutation, { loading: createUserLoading }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onCompleted({ createUser: { ok, reason } }) {
        if (!ok && reason === 'EMAIL_EXISTS') {
          toasts.addToast('error', translations.emailInUse, 5000)
          setErrors({ email: translations.emailInUseShort })
          setStage('info')
        } else if (!ok && reason === 'EMAIL_INVALID') {
          toasts.addToast('error', translations.emailInvalid)
          setErrors({ email: translations.emailInvalid })
          setStage('info')
        } else if (ok) {
          setUserCreateSuccess(true)
          startRedirectTimer()
        } else {
          toasts.addToast('error', translations.userCreateError)
        }
      },
      onError(error) {
        if (DEBUG) console.error(error)
        toasts.addToast('error', translations.userCreateError)
      },
    }
  )

  const [loginMutation, { loading: loginLoading }] = useMutation(
    LOGIN_MUTATION,
    {
      onCompleted: data => {
        if (!data.login.ok) {
          toasts.addToast('error', translations.serverError)
          return
        }

        const token = data.login.token
        setLoginToken(token, false)

        if (data.login.firstLogin) setFirstLogin()

        client.writeQuery({
          query: IS_LOGGED_IN_QUERY,
          data: {
            isLoggedIn: true,
          },
        })

        history.push('/')
      },
      onError: () => {
        toasts.addToast('error', translations.serverError)
      },
    }
  )

  const startRedirectTimer = useCallback(() => {
    setRedirectSeconds(5)
    redirectTimerRef.current = setInterval(() => {
      setRedirectSeconds(s => s - 1)
    }, 1000)
  }, [])

  useEffect(() => {
    if (redirectSeconds > 0) return

    clearInterval(redirectTimerRef.current)
    loginMutation({
      variables: {
        email,
        password,
      },
    })
    setRedirectSeconds(5)
  }, [email, loginMutation, password, redirectSeconds])

  const showInvalidToast = errors => {
    const errorItems = []
    if (errors.email !== null) errorItems.push(translations.errorEmail)
    if (errors.firstName !== null) errorItems.push(translations.errorFirstName)
    if (errors.lastName !== null) errorItems.push(translations.errorLastName)
    if (errors.password !== null) errorItems.push(translations.errorPassword)
    if (errors.passwordConfirm !== null)
      errorItems.push(translations.errorPasswordConfirm)

    if (errorItems.length > 0)
      toasts.addToast(
        'warning',
        <InvalidToastInner>
          {errorItems.map(e => (
            <li key={e}>{e}</li>
          ))}
        </InvalidToastInner>
      )
  }

  const validatePassword = () => {
    if (passwordStrength(password, 6, 128) < 1)
      return { password: translations.invalid }
    if (password !== passwordConfirm)
      return { passwordConfirm: translations.passwordsNonIdentical }

    return {}
  }
  const validateInfo = withPassword => {
    let newErrors = {
      email: /.+@.+\..+/.test(email) ? null : translations.invalid,
      firstName: !!firstName ? null : translations.invalid,
      lastName: !!lastName ? null : translations.invalid,
      password: null,
      passwordConfirm: null,
    }

    if (withPassword) {
      const passwordErrors = validatePassword()
      newErrors = { ...newErrors, ...passwordErrors }
    }

    setErrors(err => ({ ...err, ...newErrors }))

    const valid = !Object.entries(newErrors).some(([, val]) => val !== null)
    if (!valid) showInvalidToast(newErrors)

    return valid
  }
  const submitInfo = () => {
    if (!validateInfo(false)) return

    setStage('password')
  }
  const submitPassword = () => {
    if (!validateInfo(true)) return

    createUserMutation({
      variables: {
        email,
        firstName,
        lastName,
        password,
        phoneNumber: phoneNumber || null,
        newsLetter,
      },
    })
  }

  return (
    <Wrapper>
      <PoseGroup>
        {userCreateSuccess ? (
          <SuccessWrapper key="success">
            <h1>{translations.userCreated}</h1>
            <p>{translations.userCreatedText(email)}</p>
            <p className="redirect">
              {loginLoading
                ? translations.signingIn
                : translations.redirecting(redirectSeconds)}
            </p>
          </SuccessWrapper>
        ) : stage === 'info' ? (
          <CreateWrapper key="info">
            <Link className="login" to="/login">
              <span>{translations.signIn}</span>
              <Icon icon="user" />
            </Link>

            <h1>{translations.createUser}</h1>
            <p className="required" ref={requiredRef}>
              {translations.fieldsRequired}
            </p>

            <FormWrapper>
              <FormField tall noDivider>
                <Input
                  name="email"
                  value={email}
                  error={errors.email}
                  required
                  fullWidth
                  inputMode="email"
                  placeholder={translations.email}
                  disabled={createUserLoading}
                  onChange={evt => setEmail(evt.target.value)}
                />
              </FormField>

              <FormField tall>
                <Input
                  name="firstName"
                  value={firstName}
                  error={errors.firstName}
                  required
                  fullWidth
                  placeholder={translations.firstName}
                  disabled={createUserLoading}
                  onChange={evt => setFirstName(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="lastName"
                  value={lastName}
                  error={errors.lastName}
                  required
                  fullWidth
                  placeholder={translations.lastName}
                  disabled={createUserLoading}
                  onChange={evt => setLastName(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}
                  fullWidth
                  placeholder={translations.phoneNumber}
                  inputMode="tel"
                  disabled={createUserLoading}
                  onChange={evt => setPhoneNumber(evt.target.value)}
                />
              </FormField>

              <FormField tall>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    disabled={createUserLoading}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <FormattedMessage
                    id="common.terms-and-conditions-prompt"
                    values={{
                      a: msg => (
                        <a
                          href="https://www.cultura.no/om-nettsiden?fane=Personvern"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {msg}
                        </a>
                      ),
                    }}
                  />
                </label>
              </FormField>

              <FormField>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={newsLetter}
                    disabled={createUserLoading}
                    onChange={() => setNewsLetter(!newsLetter)}
                  />
                  <FormattedMessage
                    id="common.newsletter-prompt"
                    values={{
                      sender: 'Cultura Bank',
                    }}
                  />
                </label>
              </FormField>

              <FormField noDivider tall>
                <Button
                  type="button"
                  primary
                  fullWidth
                  disabled={createUserLoading || !termsAccepted}
                  isLoading={createUserLoading}
                  onClick={submitInfo}
                >
                  {termsAccepted
                    ? translations.setPassword
                    : translations.pleaseAcceptTerms}
                </Button>
              </FormField>
            </FormWrapper>
          </CreateWrapper>
        ) : (
          <CreateWrapper key="password">
            <FormWrapper>
              <FormField className="password-info">
                {translations.passwordInfo}
              </FormField>

              <FormField tall>
                <PasswordStrength password={password} />
              </FormField>

              <FormField>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  error={errors.password ?? errors.passwordConfirm}
                  required
                  fullWidth
                  placeholder={translations.password}
                  disabled={createUserLoading}
                  onChange={evt => setPassword(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  type="password"
                  name="confirm-password"
                  value={passwordConfirm}
                  required
                  fullWidth
                  placeholder={translations.passwordConfirm}
                  disabled={createUserLoading}
                  onChange={evt => setPasswordConfirm(evt.target.value)}
                />
              </FormField>

              <FormField tall>
                <Button
                  type="button"
                  primary
                  fullWidth
                  disabled={createUserLoading || !termsAccepted}
                  isLoading={createUserLoading}
                  onClick={submitPassword}
                >
                  {translations.createUser}
                </Button>
              </FormField>

              <FormField className="back">
                <span onClick={() => setStage('info')}>
                  <Icon icon="arrow-left" size="0.8rem" />
                  &nbsp;{translations.back}
                </span>
              </FormField>
            </FormWrapper>
          </CreateWrapper>
        )}
      </PoseGroup>
    </Wrapper>
  )
}

Register.propTypes = {}
Register.defaultProps = {}

export default Register
