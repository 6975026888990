import React from 'react'
import { removeLoginToken } from 'modules/login/util'
import { FullLoader } from 'components/Loader'

function Logout() {
  removeLoginToken()
  window.location.href = '/'

  return <FullLoader overlayOpacity={0.5} />
}

export default React.memo(Logout)
