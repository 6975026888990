import styled from 'styled-components'
import posed from 'react-pose'
import { buttonUnset } from 'util/style'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-color: transparent;
  ${props => !props.open && 'pointer-events: none;'}
`
export const Shade = posed(styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);
`)({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

export const Modal = posed(styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
  top: 6vh;
  left: 4vw;
  width: 92vw;
  max-height: 76%;
  max-height: calc(var(--vh, 1vh) * 88);
  padding-bottom: 1rem;

  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.defaultBackground};

  button.close {
    ${buttonUnset}
    position: absolute;
    z-index: 10001;
    top: -8px;
    right: -8px;
    width: 32px;
    height: 24px;

    background-color: ${props => props.theme.colors.defaultBackground};
    border-radius: 6px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    color: ${props => props.theme.colors.killarney};
  }
`)({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
})

export const ModalInner = styled.div`
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  div.image {
    width: 100%;
    height: 152px;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  div.info {
    position: relative;
    padding: 1rem 1rem 0;

    div.significant {
      position: absolute;
      top: 10px;
      right: 6px;

      .icon {
        &.star {
          &::before {
            font-size: 22px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
          }
        }
        &.custom {
          max-width: 22px;
          max-height: 100%;
        }
      }
    }
    span.provider {
      font-size: 0.6rem;
      color: ${props => props.theme.colors.americano};
    }
    h2 {
      margin: 0;
      font-size: 1.2rem;
      color: ${props => props.theme.colors.pinecone};
    }
    h3 {
      margin: 0;
      font-size: 0.6rem;
      text-transform: uppercase;
      color: ${props => props.theme.colors.pinecone};
    }
    p.address {
      margin: 6px 0 0;
      padding: 0;
      font-size: 0.6rem;
    }
    div.opening-times {
      margin-top: 6px;

      dl {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-auto-rows: auto;
        grid-column-gap: 1ex;

        font-size: 0.6rem;
        margin: 0;

        dt,
        dd {
          margin: 0;
        }
        dt {
          align-self: end;
          text-align: right;
          font-size: 0.5rem;
          font-weight: 600;
          text-transform: uppercase;
          color: ${props => props.theme.colors.americano};
        }
      }
    }
    button.find-in-map {
      ${buttonUnset}
      width: 100%;
      margin: 0.8rem 0 0;
      padding: 0.8rem 0;
      border: 1px solid ${props => props.theme.colors.chelseacucumber};
      background-color: rgba(143, 165, 79, 0.2);
      color: ${props => props.theme.colors.killarney};
      font-size: 0.8rem;

      &:active {
        filter: brightness(0.8);
      }
    }
    p.desc {
      white-space: pre-wrap;
      font-size: 0.8rem;
      margin: 0.8rem 0 0;
    }
  }
  div.bonuses {
    position: relative;
    padding: 1rem 1rem 0;
    z-index: 3;
  }
`

export const Visit = styled.span`
  display: block;
  margin-top: 2px;

  color: ${props =>
    props.visited ? props.theme.colors.success : props.theme.colors.error};
  font-size: 0.6rem;

  span {
    margin-left: 1ch;
    color: ${props => props.theme.colors.greensmoke};
  }
`

export const SignificantText = styled.div`
  position: absolute;
  top: -6px;
  right: 42px;

  box-sizing: border-box;
  width: 70vw;
  max-width: 70vw;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;

  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  opacity: 0;
  transition: opacity 0.2s ease-out;

  &.visible {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);

    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgba(0, 0, 0, 0.8);
  }
`

export const TitleSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`

export const PrimaryInfo = styled.div``

export const VisitButtonWrapper = styled.div`
  align-self: center;
  justify-self: end;
  margin-right: 0.5rem;
`

export const RegisterVisitButton = styled.button`
  ${buttonUnset}

  border: 2px solid rgba(110, 98, 89, 0.8);
  border-radius: 8px;
  background: rgba(233, 230, 228, 0.8);

  color: ${props => props.theme.colors.pinecone};
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;

  padding: 1rem 1.2rem;

  &:active {
    filter: brightness(0.8);
  }
`
