import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { cssSize } from 'util/prop-type-validators'
import { overloadColor } from 'util/style'

const I = styled.i`
  font-size: ${props => props.size};

  span[class^="path"]::before { color: ${props => overloadColor(props.color)} !important; }
  span.path1::before { color: ${props => overloadColor(props.background)} !important; }
`

function CulturaIcon({
  className,

  icon,
  size,
  background,
  color,
  onClick
}) {
  return (
    <I
      className={`cul cul-${icon} ${className}`}
      size={size}
      background={background}
      color={color}
      onClick={onClick}
    >
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
    </I>
  )
}

CulturaIcon.propTypes = {
  className: PropTypes.string,

  icon: PropTypes.string.isRequired,
  size: cssSize,
  color: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func,
}
CulturaIcon.defaultProps = {
  size: '1em',
  color: 'white',
  background: 'rgb(106, 150, 59)',
  onClick: () => void 0,
}

export default CulturaIcon
