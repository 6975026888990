import gql from 'graphql-tag'
import { UserFragments } from './fragments'

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserInfo
    }
  },
  ${UserFragments.info}
`