import React from 'react'
import styled from 'styled-components'

import { useEvent } from 'util/hooks'
import { htmlifyUrls } from 'util/helpers'

const Wrapper = styled.div`
  padding: 8px;
`
const RewardCard = styled.section`
  background-color: ${props => props.theme.colors.desertstorm};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);

  &:not(:first-child) {
    margin-top: 1rem;
  }

  div.image {
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
    }
  }
  div.info {
    padding: 1rem;

    h2 {
      margin: 0;
      font-size: 1rem;
    }
    p {
      margin: 0.8rem 0 0 0;
      white-space: pre-wrap;
      font-size: 0.8rem;
    }
  }
`

function Rewards() {
  const event = useEvent()

  const rewards = event.rewards.edges
    .map(edge => edge.node)
    .filter(reward => reward.active)
    .sort((a, b) => b.priority - a.priority)

  return (
    <Wrapper>
      {rewards.map(reward => (
        <RewardCard key={reward.id}>
          <div className="image">
            <img src={reward.image} alt="Reward"/>
          </div>
          <div className="info">
            <h2>{reward.name}</h2>
            <p dangerouslySetInnerHTML={htmlifyUrls(reward.description)} />
          </div>
        </RewardCard>
      ))}
    </Wrapper>
  )
}

Rewards.propTypes = { }
Rewards.defaultProps = { }

export default Rewards
