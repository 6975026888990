import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

import { cssSize } from 'util/prop-type-validators'
import { getTextWidth } from 'util/helpers'

import Icon from 'components/Icon'
import Label from 'components/Label'
import { useCallback } from 'react'

function getGridSetup(props) {
  let _grid = ''
  let { mobile, label, height } = props
  height = height || (mobile ? '32px' : '42px')

  if (mobile) {
    _grid += 'grid-template-columns: 1fr 1fr;'
    _grid += `grid-template-rows: auto ${height} ${height};`
    _grid += 'grid-gap: 8px;'
    _grid += '.-label { margin-bottom: -8px; }'

    if (label) {
      _grid += `
        grid-template-areas:
          'label    label'
          'input    input'
          'subtract add';
      `
    } else {
      _grid += `
        grid-template-areas:
          'input    input'
          'input    input'
          'subtract add';
      `
    }
  } else {
    _grid += 'grid-template-columns: 1fr 58px 58px;'
    _grid += `grid-template-rows: auto ${height};`
    _grid += 'grid-column-gap: 8px;'

    if (label) {
      _grid += `
        grid-template-areas:
          'label label label'
          'input add subtract';
      `
    } else {
      _grid += `
        grid-template-areas:
          'input add subtract'
          'input add subtract';
      `
    }
  }

  return _grid
}
const Wrapper = styled.div`
  display: grid;
  ${getGridSetup}

  width: ${props => props.width || '300px'};
`
const LabelWrapper = styled.div`
  grid-area: label;
`
const InputWrapper = styled.div`
  grid-area: input;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;

  height: 100%;
  padding: 0 8px;

  background-color: white;
  border: 1px solid ${props => props.theme.colors.verylightgray};
  border-radius: 4px;
`
const InputInnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;

  ${props =>
    props.error &&
    `
    &::after {
      content: '${props.error}';
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0.6rem;
      color: ${props.theme.colors.error};
    }
  `}
`
const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  border: none;
  appearance: none;
  outline: none;
  ${props => props.mobile && 'padding-top: 2px;'}
`
const Suffix = styled.span`
  position: absolute;
  left: 0;
  top: 4px;
  padding-left: 6px;
  font-size: 0.8em;
  color: ${props => props.theme.colors.gray};

  /* iOS */
  @supports (-webkit-overflow-scrolling: touch) {
    top: 8px;
    transform: translateX(6px);
  }
`
const SpinnerButton = styled.div`
  grid-area: ${props => props.type};
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.primary};

  &:hover {
    filter: brightness(1.3);
  }
  &:active {
    filter: brightness(1.5);
  }
`

function NumberInput({
  value,
  error,

  min,
  max,
  interval,

  fullWidth,
  width,
  height,
  label,
  labelStyle,
  suffix,
  suffixSingular,
  addButtonBackgroundColor,
  subtractButtonBackgroundColor,

  mobile,

  onChange,
}) {
  const inputRef = useRef()
  const suffixRef = useRef()

  const [renderedValue, setRenderedValue] = useState(String(value))
  const [renderedSuffix, setRenderedSuffix] = useState(suffix)

  const updateSuffix = useCallback(
    inputText => {
      if (suffix) {
        const inputElement = inputRef.current
        const suffixElement = suffixRef.current

        const computedStyle = window.getComputedStyle(inputElement, null)
        const fontFamily = computedStyle
          .getPropertyValue('font-family')
          .split(',')[0]
        const fontSize = computedStyle.getPropertyValue('font-size')
        const font = `${fontSize} ${fontFamily}`

        const textWidth = getTextWidth(inputText, font)
        suffixElement.style.left = `${textWidth}px`

        if ((inputText === '1' || inputText === '-1') && suffixSingular) {
          setRenderedSuffix(suffixSingular)
        } else {
          setRenderedSuffix(suffix)
        }
      }
    },
    [suffix, suffixSingular]
  )

  useEffect(() => {
    setRenderedValue(String(value))
  }, [value])
  useEffect(() => {
    updateSuffix(renderedValue)
  }, [renderedValue, updateSuffix])

  const changeHandler = evt => {
    let inputText = typeof evt === 'number' ? String(evt) : evt.target.value
    const lastChar = inputText.charAt(inputText.length - 1)
    let lastCharDecimal = false
    if (lastChar === ',' || lastChar === '.') {
      lastCharDecimal = true
      inputText = inputText.substring(0, inputText.length - 1)
      if (inputText.indexOf('.') >= 0) {
        return
      }
    }

    if (
      (inputText === '-0' || inputText === '0-' || inputText === '-') &&
      min < 0
    ) {
      setRenderedValue('-')
      return
    }

    const pattern = /^-?\d+(\.\d+)?$/
    if (inputText === '') {
      inputText = '0'
    }
    if (!pattern.test(inputText)) {
      // TODO show some error? Loud, annoying beep, maybe? Barf sound?
      return
    }

    if (lastCharDecimal) {
      setRenderedValue(inputText + '.')
    } else {
      const parsed = parseFloat(inputText)
      if (parsed < min || parsed > max) {
        return
      }
      if (
        parsed === value &&
        renderedValue.charAt(renderedValue.length - 1) === '.'
      ) {
        setRenderedValue(inputText)
      }

      onChange(parsed)
    }
  }
  const keyDownHandler = evt => {
    switch (evt.key) {
      case 'ArrowDown':
        onChange(value - interval)
        break
      case 'ArrowUp':
        onChange(value + interval)
        break
      default:
        break
    }
  }

  const randomId = uuid()
  const _height = height || (mobile ? '32px' : '42px')

  return (
    <Wrapper label={label} width={width} height={_height} mobile={mobile}>
      {label && (
        <LabelWrapper className="-label">
          <Label htmlFor={randomId} labelStyle={labelStyle}>
            {label}
          </Label>
        </LabelWrapper>
      )}
      <InputWrapper>
        <InputInnerWrapper error={error}>
          <Input
            id={randomId}
            ref={inputRef}
            value={renderedValue}
            inputMode="numeric"
            mobile={mobile}
            onChange={evt => changeHandler(evt)}
            onKeyDown={keyDownHandler}
          />
          {suffix && <Suffix ref={suffixRef}>{renderedSuffix}</Suffix>}
        </InputInnerWrapper>
      </InputWrapper>
      <SpinnerButton
        type="add"
        color={addButtonBackgroundColor}
        onClick={() => changeHandler(value + interval)}
      >
        <Icon icon="plus" color="white" />
      </SpinnerButton>
      <SpinnerButton
        type="subtract"
        color={subtractButtonBackgroundColor}
        onClick={() => changeHandler(value - interval)}
      >
        <Icon icon="minus" color="white" />
      </SpinnerButton>
    </Wrapper>
  )
}

NumberInput.propTypes = {
  value: PropTypes.number.isRequired,
  error: PropTypes.string,

  min: PropTypes.number,
  max: PropTypes.number,
  interval: PropTypes.number,

  fullWidth: PropTypes.bool,
  width: cssSize,
  height: cssSize,
  label: PropTypes.string,
  labelStyle: PropTypes.any,
  suffix: PropTypes.string,
  suffixSingular: PropTypes.string,
  addButtonBackgroundColor: PropTypes.string,
  subtractButtonBackgroundColor: PropTypes.string,

  mobile: PropTypes.bool,

  onChange: PropTypes.func,
}
NumberInput.defaultProps = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  interval: 1,
  fullWidth: false,
  width: '300px',

  mobile: false,

  onChange: () => null,
}

export default NumberInput
