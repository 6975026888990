import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { cssSize } from 'util/prop-type-validators'
import { overloadColorProp } from 'util/style'

function Icon({
  className,
  icon,
  solid,
  light,
  brand,
  custom,
  title,

  onClick
}, ref) {
  let iconName
  let iconStyle = solid ? 'fa' : (light ? 'fal' : (brand ? 'fab' : (custom ? 'custom' : 'far')))

  const iconSplitted = icon.split(' ')
  if (iconSplitted.length > 1) {
    if (['fa', 'fal', 'far', 'fab', 'custom'].includes(iconSplitted[0])) {
      iconStyle = iconSplitted[0]
    }
    iconName = iconSplitted[1]
    const prefix = /^fa[lrb]*$/.test(iconStyle) ? 'fa-' : 'custom-'
    iconName = iconName.indexOf(prefix) !== 0 ? `${prefix}${iconName}` : iconName
  } else {
    iconName = iconSplitted[0]
    const prefix = /^fa[lrb]*$/.test(iconStyle) ? 'fa-' : 'custom-'
    iconName = iconName.indexOf(prefix) !== 0 ? `${prefix}${iconName}` : iconName
  }

  const iconClass = `${iconStyle} ${iconName}`
  return <i ref={ref} className={`${iconClass} ${className}`} title={title} onClick={onClick} />
}

const RefIcon = React.forwardRef(Icon)

const StyledIcon = styled(RefIcon)`
  color: ${props => overloadColorProp(props)};
  font-size: ${props => props.size};

  ${props => props.hoverColor
    ? `
      &:hover {
        color: ${overloadColorProp({ theme: props.theme, color: props.hoverColor })};
      }
    ` : ''}
`

StyledIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  solid: PropTypes.bool,
  regular: PropTypes.bool,
  light: PropTypes.bool,
  brand: PropTypes.bool,
  custom: PropTypes.bool,

  color: PropTypes.string,
  hoverColor: PropTypes.string,
  size: cssSize,
  title: PropTypes.string,

  onClick: PropTypes.func
}
StyledIcon.defaultProps = {
  color: 'inherit',
  size: '1em',
  regular: true,
  solid: false,
  light: false,
  brand: false,
  custom: false,

  onClick: () => null
}

export default StyledIcon