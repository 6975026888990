import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useQuery } from '@apollo/react-hooks'

import { IS_LOGGED_IN_QUERY } from 'modules/login/queries'

import { FullLoader } from 'components/Loader'
import CenteredErrorMessage from 'components/CenteredErrorMessage'

function PrivateRoute({
  component: Component,
  render,
  ...rest
}) {
  const { loading, error, data } = useQuery(IS_LOGGED_IN_QUERY)
  if (loading || !data) return <FullLoader />
  if (error) return <CenteredErrorMessage />

  const isLoggedIn = data.isLoggedIn

  return (
    <Route {...rest} render={props => (
      isLoggedIn ? (
        render ? render(props) : <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
    )} />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any
}

PrivateRoute.defaultProps = {

}

export default PrivateRoute
