import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { cssSize } from 'util/prop-type-validators'
import Icon from 'components/Icon'

const Wrapper = styled.span`
  display: flex;
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};

  &:hover,
  &:focus-within {
    .icon {
      color: #333;
    }
  }

  ${props =>
    props.label &&
    `
    &::before {
      content: '${props.label}';
      position: absolute;
      left: 4px;
      top: 0;
      transform: translateY(-100%);

      color: ${props.theme.colors.grayText};
      ${props.labelStyle}
    }
  `}

  ${props =>
    props.required &&
    `
    &::after {
      content: '*';
      position: absolute;
      top: 0;
      right: 4px;
      color: ${props.theme.colors.error};
    }
  `}
`

const StyledInput = styled.input`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  ${props => props.icon && `padding-left: ${props.height};`}

  border: 1px solid ${props => props.theme.colors.pineglade};

  ${props =>
    props.error !== null
      ? `
    color: ${props.theme.colors.red};
    border-color: ${props.theme.colors.red};
  `
      : ''}

  font-size: ${props => props.fontSize};
  font-weight: 400;
  color: ${props => props.color || props.theme.colors.text};
  ${props => (props.background ? `background: ${props.background};` : '')}
  ${props =>
    props.shadowed
      ? 'box-shadow: 0 1px 3px 2px rgba(0, 130, 0, 0.1);'
      : `border: 1px solid ${props.theme.colors.verylightgray}`};

  &::placeholder {
    color: ${props => props.theme.colors.verylightgray};
  }
`

const IconWrapper = styled.span`
  align-self: flex-end;
  height: ${props => props.height};
  color: #aaa;
  transition: color 0.1s linear;

  i {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    width: ${props => props.height};
    text-align: center;
    font-size: 1em;
  }
`
const ErrorWrapper = styled.span`
  &::after {
    content: '${props => props.error}';
    position: absolute;
    z-index: 3;
    top: ${props => (props.label && !props.mobile ? 'calc(50% + 2px)' : '50%')};
    ${props => (props.errorPosition === 'left' ? 'left: 6px;' : 'right: 6px;')}
    transform: translateY(-50%);

    height: 16px;
    padding-right: 0.5rem;
    padding-left: 2em;

    line-height: 16px;
    font-size: 12px;

    background: linear-gradient(to right, transparent, white 2em);
    color: ${props => props.theme.colors.error};
  }
`

const VisibilityIcon = styled(Icon)`
  position: absolute;
  top: calc(50% + 1px);
  right: 8px;
  z-index: 200;
  transform: translateY(-50%);

  font-size: 1.2rem;
`

function Input(
  {
    className,
    type,
    id,
    value,
    error,
    errorPosition,

    icon,
    autoFocus,
    autoSelect,
    label,
    labelStyle,
    disabled,
    placeholder,

    fullWidth,
    width,
    height,
    fontSize,

    inputMode,
    autoCorrect,
    autoCapitalize,
    readOnly,
    required,
    passwordVisibilityToggle,

    mobile,

    onChange,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    onClick,
  },
  ref
) {
  const inputRef = useRef(null)
  const [passwordVisible, setPasswordVisible] = useState(false)

  useEffect(() => {
    if (autoSelect) {
      const _ref = ref || inputRef
      const interval = setInterval(() => {
        try {
          _ref.current.select()
          clearInterval(interval)
        } catch (e) {}
      }, 50)
    }
  }, [autoSelect, ref])

  const _icon = typeof icon === 'string' ? <Icon icon={icon} /> : icon

  return (
    <Wrapper
      width={fullWidth ? '100%' : width}
      height={height}
      label={mobile ? null : label}
      labelStyle={labelStyle}
      required={required}
    >
      {icon && (
        <IconWrapper className="icon" height={height}>
          {_icon}
        </IconWrapper>
      )}
      {error && errorPosition === 'left' && (
        <ErrorWrapper error={error} errorPosition={errorPosition} />
      )}
      <StyledInput
        ref={ref || inputRef}
        id={id}
        className={className}
        defaultValue={value}
        type={type === 'password' && passwordVisible ? 'text' : type}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={mobile ? label : placeholder}
        icon={!!icon}
        height={height}
        fontSize={fontSize}
        inputMode={inputMode}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      />
      {error && errorPosition === 'right' && <ErrorWrapper error={error} />}
      {type === 'password' && passwordVisibilityToggle && (
        <VisibilityIcon
          icon={passwordVisible ? 'eye-slash' : 'eye'}
          color="pinecone"
          onClick={() => setPasswordVisible(!passwordVisible)}
        />
      )}
    </Wrapper>
  )
}

const RefInput = React.forwardRef(Input)

RefInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  mobile: PropTypes.bool,

  value: PropTypes.any.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  errorPosition: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string,
  labelStyle: PropTypes.any,

  passwordVisibilityToggle: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoSelect: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['true', 'false']),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputMode: PropTypes.string,
  checked: PropTypes.bool,
  required: PropTypes.bool,

  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,

  color: PropTypes.string,
  background: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fontSize: cssSize,
  shadowed: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onEnterKey: PropTypes.func,
}

RefInput.defaultProps = {
  type: 'text',
  mobile: false,

  error: null,
  errorPosition: 'right',
  label: null,

  passwordVisibilityToggle: false,
  disabled: false,
  autoSelect: false,
  readOnly: false,
  inputMode: 'text',
  checked: false,
  autoCorrect: 'true',
  autoCapitalize: 'sentences',
  required: false,

  fullWidth: false,
  width: '300px',
  height: '46px',

  fontSize: '1rem',
  shadowed: false,
  icon: null,

  onFocus: () => void 0,
  onBlur: () => void 0,
  onKeyDown: () => void 0,
  onKeyUp: () => void 0,
  onEnterKey: () => void 0,
}

export default RefInput
