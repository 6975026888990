import { useMutation } from '@apollo/react-hooks'
import client from 'apollo'
import FullLoader from 'components/Loader/FullLoader'
import { IS_LOGGED_IN_QUERY } from 'modules/login/queries'
import { setLoginToken } from 'modules/login/util'
import React, { useLayoutEffect, useRef } from 'react'
import { toasts } from 'store'
import { DEBUG } from 'util/env'
import { useIntl, useRouter } from 'util/hooks'
import { REGISTER_USER_MUTATION } from './mutations'

function RegisterUser({ user }) {
  const firstMount = useRef(true)

  const { history, match } = useRouter()
  const {
    params: { hash },
  } = match

  const intl = useIntl()
  const translations = {
    userActivated: intl.fm('users.user-activated'),
    userActivateError: intl.fm('users.user-activate-error'),
    userAlreadyActivated: intl.fm('users.user-already-activated'),
    signingIn: intl.fm('login.signing-in'),
    activatingUser: intl.fm('users.activating-user'),
  }

  const [registerUserMutation, { loading: registerUserLoading }] = useMutation(
    REGISTER_USER_MUTATION,
    {
      refetchQueries: ['Me'],
      async onCompleted(data) {
        if (!data.registerUser.ok) {
          toasts.addToast('error', translations.userActivateError)
          return
        }
        toasts.addToast('success', translations.userActivated)

        const token = data.registerUser.token
        setLoginToken(token, false)

        await client.writeQuery({
          query: IS_LOGGED_IN_QUERY,
          data: {
            isLoggedIn: true,
          },
        })

        history.push('/')
      },
      onError(error) {
        if (error.message.indexOf('already registered') > -1) {
          toasts.addToast('info', translations.userAlreadyActivated)
          history.push('/')
          return
        }

        if (DEBUG) console.error(error)
        toasts.addToast('error', translations.userActivateError)
      },
    }
  )

  useLayoutEffect(() => {
    if (!firstMount.current) return
    firstMount.current = false

    registerUserMutation({
      variables: {
        user: user.id,
        confirmationHash: hash,
      },
    })
  }, [hash, registerUserMutation, user.id])

  if (registerUserLoading) return <FullLoader text={translations.signingIn} />

  return <FullLoader text={translations.activatingUser} />
}

RegisterUser.propTypes = {}
RegisterUser.defaultProps = {}

export default RegisterUser
