import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { cssSize } from 'util/prop-type-validators'

const InputError = styled.div`
  position: absolute;
  top: -16px;
  height: 16px;
  line-height: 16px;
  right: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.red};
`

const InputWrapper = styled.span`
  display: block;
  position: relative;
  width: 100%;
`

const EnterText = styled.span`
  position: absolute;
  bottom: 10px;
  right: 0;

  font-size: 10px;
  text-transform: uppercase;

  border: 1px solid #ccc;
  border-radius: 4px;

  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05);
  padding: 0.35em .5em;

  color: ${props => props.theme.colors.lightgray};

  background: white;

  cursor: pointer;
`

export function FlatInput({
  id,
  className,
  onChange,
  onKeyDown,
  value,
  type,
  autoFocus,
  error,
  placeholder,
  enterText,
  onEnterClicked
}) {
  return (
    <InputWrapper>
      {error !== null && (
        <InputError>{error}</InputError>
      )}
      <input
        id={id}
        className={className}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        type={type}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      <EnterText onClick={onEnterClicked}>{enterText}</EnterText>
    </InputWrapper>
  )
}

FlatInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.any.isRequired,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  enterText: PropTypes.string,
  width: cssSize,
  fullWidth: PropTypes.bool
}

FlatInput.defaultProps = {
  type: 'text',
  enterText: 'Enter',

  width: '200px',
  fullWidth: false
}


export const StyledInput = styled(FlatInput)`
  font-size: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 17px;

  border: none;
  outline: none;

  border-bottom: 1px solid ${props => props.theme.colors.lightgray};
  width: ${props => props.fullWidth ? '100%' : props.width};
  height: 36px;

  color: ${props => props.theme.colors.gray};
  font-weight: 600;

  box-sizing: border-box;
  background: transparent;
  transition: border-color 0.2s ease-in-out;

  :focus {
    border-color: ${props => props.theme.colors.gray};
  }

  ::placeholder{
    color: ${props => props.theme.colors.lightgray};
  }
`


StyledInput.propTypes = {
  background: PropTypes.string,
  error: PropTypes.string
}

StyledInput.defaultProps = {
  error: null
}

export default StyledInput
