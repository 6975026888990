import React, { useState } from 'react'
import styled from 'styled-components'
import QRPortal from 'react-qr-reader'
import { useRef } from 'react'
import { useImperativeHandle } from 'react'
import { isIOS, isMobileSafari } from 'react-device-detect'
import { animated, useTransition } from 'react-spring'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  margin: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background: orangered;
  color: white;
`
const BoringModal = styled.div`
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 4vw;
  width: 92vw;
  height: 92vw;

  background-color: ${props => props.theme.colors.ebb};
  box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.2);

  div.close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    padding: 8px 16px 4px;

    border-radius: 14px 14px 0 0;
    background-color: inherit;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  }
`
const Modal = animated(BoringModal)

function QRReader({ children, hidden, onSuccess, onError }, ref) {
  const qrRef = useRef()

  useImperativeHandle(ref, () => ({
    click: handleClick,
  }))

  const [backup, setBackup] = useState(isIOS && !isMobileSafari)
  const [open, setOpen] = useState(false)

  function handleScan(result) {
    if (result === null) return

    setOpen(false)
    onSuccess({ result })
  }

  function handleError() {
    onError('Not allowed to use camera')

    setBackup(true)
    setTimeout(() => {
      handleClick()
    }, 100)
  }

  function handleClick() {
    if (backup) {
      if (!qrRef.current) return
      qrRef.current.openImageDialog()
      return
    }

    setOpen(true)
  }

  const transitions = useTransition(open, {
    from: {
      scale: 0.8,
      opacity: 0,
      transform: 'translateY(-50%)',
    },
    enter: {
      scale: 1,
      opacity: 1,
      transform: 'translateY(-50%)',
    },
    leave: {
      scale: 0.8,
      opacity: 0,
      transform: 'translateY(-50%)',
    },
  })

  return (
    <>
      {backup ? (
        <Wrapper hidden={hidden} onClick={handleClick}>
          <QRPortal
            ref={qrRef}
            style={{
              width: '100%',
              height: '100%',
            }}
            legacyMode
            onScan={handleScan}
            onError={onError}
          />
          {children}
        </Wrapper>
      ) : (
        transitions(
          (style, isOpen) =>
            isOpen && (
              <Modal style={style}>
                <div className="close">
                  <Icon
                    icon="times"
                    size="1.4rem"
                    color="pinecone"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <QRPortal
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  onScan={handleScan}
                  onError={handleError}
                />
              </Modal>
            )
        )
      )}
    </>
  )
}

export default React.forwardRef(QRReader)
