import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'assets/style/cultura-custom-icons/style.css'
import * as serviceWorker from './serviceWorker'
import './locale/bootstrap.js'

import Root from './containers/Root'

ReactDOM.render(<Root />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    const NextRoot = require('./containers/Root').default
    ReactDOM.render(<NextRoot />, document.getElementById('root'))
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
