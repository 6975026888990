import CenteredErrorMessage from 'components/CenteredErrorMessage'
import { FullLoader } from 'components/Loader'
import PrivateRoute from 'containers/PrivateRoute'
import UserContext from 'context/UserContext'
import About from 'modules/about'
import Dashboard from 'modules/dashboard'
import Help from 'modules/help'
import { Header } from 'modules/layout'
import Login, { ForgottenPassword, Logout } from 'modules/login'
import Map from 'modules/map'
import ControlPoints from 'modules/points'
import Rewards from 'modules/rewards'
import { ChangePassword, Profile } from 'modules/users'
import { ME_QUERY } from 'modules/users/queries'
import React from 'react'
import { Query } from 'react-apollo'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import styled from 'styled-components'

const MainLayout = styled.div`
  width: 100%;
`

const HeaderWrapper = styled.header`
  grid-area: header;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 52px;
  background-color: ${props => props.theme.colors.defaultBackground};
`
const ContentWrapper = styled.main`
  grid-area: content;
  padding-top: 52px;
  background-color: ${props => props.theme.colors.defaultBackground};
`

const eventBasePathRegex = /^\/e\/[\w-]+/

export default function Routes() {
  const match = window.location.pathname.match(eventBasePathRegex)

  if (!match) return <h1>Wrong subpath</h1>

  return (
    <Router basename={match[0]}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        {/* This nested functionality makes sure we only render the main layout when logged in */}
        <PrivateRoute
          path=""
          render={() => (
            <Query query={ME_QUERY}>
              {({ loading, data, error }) => {
                if (loading) return <FullLoader text="Laster" />
                if (error || !data)
                  return <CenteredErrorMessage message={error ?? undefined} />

                const me = data.me
                if (!me) {
                  window.location.href = '/login' // FIXME Feels like a weird solution
                  return null
                }

                // NOTE just... skip this for now
                // if (!me.hasRegistered) {
                //   return (
                //     <UserContext.Provider value={me}>
                //       <RegisterUser user={me} />
                //     </UserContext.Provider>
                //   )
                // }
                if (me.needsPasswordChange) {
                  return (
                    <UserContext.Provider value={me}>
                      <ChangePassword />
                    </UserContext.Provider>
                  )
                }

                return (
                  <UserContext.Provider value={me}>
                    <MainLayout>
                      <HeaderWrapper>
                        <Header />
                      </HeaderWrapper>
                      <ContentWrapper>
                        <Switch>
                          <PrivateRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                          />
                          <PrivateRoute
                            exact
                            path="/points"
                            component={ControlPoints}
                          />
                          <PrivateRoute exact path="/map" component={Map} />
                          <PrivateRoute
                            exact
                            path="/rewards"
                            component={Rewards}
                          />
                          <PrivateRoute
                            exact
                            path="/profile"
                            component={Profile}
                          />
                          <PrivateRoute exact path="/help" component={Help} />
                          <PrivateRoute exact path="/about" component={About} />

                          <PrivateRoute
                            exact
                            path="/logout"
                            component={Logout}
                          />

                          <Redirect from="/" to="/dashboard" />
                        </Switch>
                      </ContentWrapper>
                    </MainLayout>
                  </UserContext.Provider>
                )
              }}
            </Query>
          )}
        />
      </Switch>
    </Router>
  )
}
