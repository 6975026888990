import gql from 'graphql-tag'
import { EventFragments } from './fragments'

export const CURRENT_EVENT_QUERY = gql`
  query CurrentEvent {
    currentEvent {
      ...EventInfo
    }
  }
  ${EventFragments.info}
`

export const MY_EVENTS_QUERY = gql`
  query MyEvents {
    me {
      id
      events {
        edges {
          node {
            ...EventInfo
          }
        }
      }
    }
  }
  ${EventFragments.info}
`

export const ALL_EVENTS_QUERY = gql`
  query AllEvents {
    allEvents {
      edges {
        node {
          ...EventInfo
        }
      }
    }
  }
  ${EventFragments.info}
`