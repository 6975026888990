import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import posed, { PoseGroup } from 'react-pose'

import { buttonUnset } from 'util/style'
import { useUser, useEventListener } from 'util/hooks'

import blader from 'assets/images/blader_gronn.png'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-color: transparent;
  ${props => !props.open && 'pointer-events: none;'}
`

const Shade = posed(styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);
`)({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})
const Modal = posed(styled.aside`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000000;
  top: 6vh;
  left: 4vw;
  width: 92vw;
  max-height: 76%;
  max-height: calc(var(--vh, 1vh) * 88);
  padding: 1rem;

  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.defaultBackground};

  button.close {
    ${buttonUnset}

    position: absolute;
    z-index: 10001;
    top: -8px;
    right: -8px;
    width: 32px;
    height: 24px;

    background-color: ${props => props.theme.colors.defaultBackground};
    background-image: none;
    border-radius: 6px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    color: ${props => props.theme.colors.killarney};
  }
`)({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100
  },
})
const ModalInner = styled.div`
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  img {
    width: 100%;
    height: 60px;
    object-fit: cover;
  }
  h2 {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.killarney};
  }
  p {
    white-space: pre-wrap;
  }
`

function FirstLoginPopup({
  show,
  onClose
}) {
  const user = useUser()

  const setVHProp = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  useEffect(setVHProp, [])
  useEventListener('resize', setVHProp)

  const userName = user.firstName
    ? user.firstName
    : (user.lastName || user.email.split('@')[0])

  return (
    <Wrapper open={show}>
      <PoseGroup>
        {show && [
          <Shade key="shade" onClick={onClose} />,
          <Modal key="modal">
            <button
              type="button"
              className="close"

              onClick={onClose}
            >
              <Icon icon="times" />
            </button>
            <ModalInner>
              <img src={blader} alt="blader"/>

              <h2>Velkommen til Økojakt!</h2>

              <p>
                Hei {userName}!
              </p>
              <p>
                Denne appen lar deg utforske forskjellige arrangementer hvor målet er å besøke steder for å samle poeng så du kan vinne premier. Jo flere steder du besøker, jo større blir vinnersjansene dine!
              </p>
              <p>
                Du kan også legge til denne appen på hjem-skjermen på mobilen din for en bedre opplevelse. Trykk på <em>Hjelp</em> i sidemenyen om du er usikker på hvordan.
              </p>
              <p>
                I menyen til venstre (trykk på knappen i øvre venstre hjørne) finner du:
              </p>
              <p><b>Kart</b><br/>
              Utforsk arrangementsområdet. Du kan trykke på ikonene i kartet for å se informasjon om stedene. Gå til stedene, finn plakat med QR-kode og trykk på <em>Registrér besøk</em>. Du kan da scanne/ta bilde av QR-koden og vil få tilgang til eventuelle tilbudskuponger stedet tilbyr.
              </p>
              <p><b>Steder</b><br/>
                Oversikt over alle arrangementets poster.
              </p>
              <p><b>Premier</b><br/>
                Oversikt over hva du kan vinne ved arrangementets slutt.
              </p>
              <p><b>Profil</b><br/>
                I menyen øverst til høyre kan du endre profilen din, logge ut og slette brukeren din og all informasjonen vi har lagret om deg.
              </p>
              <p>
                Dette vinduet vises kun første gang du logger inn. Du kan finne hjelp i menyen om du trenger det. Lukk dette vinduet for å komme i gang.
              </p>
              <img src={blader} alt="blader"/>
            </ModalInner>
          </Modal>
        ]}
      </PoseGroup>
    </Wrapper>
  )
}

FirstLoginPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
FirstLoginPopup.defaultProps = {}

export default FirstLoginPopup
