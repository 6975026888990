import React from 'react'
import styled from 'styled-components'

import { useEvent, useIntl } from 'util/hooks'
import { htmlifyUrls } from 'util/helpers'
import UrFooter from 'components/UrFooter/index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);

  div.image {
    width: 100%;

    img {
      width: 100%;
      max-height: 100%;
    }
  }
  div.info {
    padding: 1rem 1rem 0;

    h2 {
      margin: 0;
      font-size: 1.2rem;
      color: ${props => props.theme.colors.pinecone};
    }
    p {
      white-space: pre-wrap;
    }
  }
  div.separator {
    flex: 1 1 auto;
  }
`
const Contact = styled.address`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 4px;
  width: 100%;
  margin: 0 1rem;

  font-style: normal;

  label {
    justify-self: end;
    align-self: end;
    padding: 0 8px 1px 0;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.americano};
  }
  div.detail {
    align-self: end;

    a {
      text-decoration: none;
    }
  }
`

function About() {
  const { organizer } = useEvent()

  const intl = useIntl()
  const translations = {
    unknown: intl.fm('common.unknown'),
    webSite: intl.fm('common.web-site'),
    email: intl.fm('common.email'),
    phone: intl.fm('common.phone'),
  }

  const getDomain = url => {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    return match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0
      ? match[2]
      : url
  }

  return (
    <Wrapper>
      <div className="image">
        <img src={organizer.image} alt={organizer.name} />
      </div>

      <div className="info">
        <h2>{organizer.name}</h2>

        <p dangerouslySetInnerHTML={htmlifyUrls(organizer.description)} />
      </div>

      <Contact>
        <label>{translations.webSite}</label>
        <div className="detail">
          {organizer.homepage ? (
            <a
              href={organizer.homepage}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDomain(organizer.homepage)}
            </a>
          ) : (
            <>{translations.unknown}</>
          )}
        </div>

        <label>{translations.email}</label>
        <div className="detail">
          {organizer.email ? (
            <a href={`mailto:${organizer.email}`}>{organizer.email}</a>
          ) : (
            <>{translations.unknown}</>
          )}
        </div>

        <label>{translations.phone}</label>
        <div className="detail">
          {organizer.phoneNumber ? (
            <a href={`tel:${organizer.phoneNumber}`}>{organizer.phoneNumber}</a>
          ) : (
            <>{translations.unknown}</>
          )}
        </div>
      </Contact>

      <div className="separator"></div>

      <UrFooter />
    </Wrapper>
  )
}

export default About
