import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { PoseGroup } from 'react-pose'

import moment from 'moment'
import {
  useIntl,
  useRouter,
  useEventListener,
  useUser,
  useEvent,
} from 'util/hooks'

import DetectClickOutside from 'containers/DetectClickOutside'
import Icon from 'components/Icon'
import ControlPointBonusList from 'modules/points/ControlPointBonusList'
import {
  Wrapper,
  Shade,
  Modal,
  ModalInner,
  Visit,
  SignificantText,
  TitleSection,
  PrimaryInfo,
  VisitButtonWrapper,
  RegisterVisitButton,
} from './components/ControlPointPopup'
import { useCallback } from 'react'
import QRReader from 'components/QRReader/index'

function ControlPointPopup({
  open,
  controlPoint,

  findInMapButton,

  onClose,
}) {
  const user = useUser()
  const {
    significantControlPointIcon,
    significantControlPointText,
  } = useEvent()

  const { history } = useRouter()

  const [showSignificantText, setShowSignificantText] = useState(false)
  const [bonuses, setBonuses] = useState([])

  const qrRef = useRef(null)

  useEffect(() => {
    const _bonuses = controlPoint
      ? controlPoint.bonuses.edges.map(({ node }) => {
          const { __typename, userBonuses, ...rest } = node

          let _userBonus = userBonuses.edges.find(
            edge => edge.node.user.id === user.id
          )
          _userBonus = _userBonus ? _userBonus.node : null

          return {
            ...rest,
            userBonus: _userBonus
              ? {
                  id: _userBonus.id,
                  usedAt: _userBonus.usedAt,
                }
              : null,
          }
        }, [])
      : []
    setBonuses(_bonuses)
  }, [controlPoint, user.id])

  const intl = useIntl()
  const translations = {
    openingTimes: intl.fm('points.opening-times'),
    opening: intl.fm('points.opening'),
    closing: intl.fm('points.closing'),
    unknown: intl.fm('common.unknown'),
    visited: intl.fm('points.visited'),
    notVisited: intl.fm('points.not-visited'),
    findInMap: intl.fm('points.find-in-map'),
    noDescription: intl.fm('points.no-description'),
    registerVisit: intl.fm('map.register-visit'),
  }

  const setVHProp = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  useEffect(() => {
    setVHProp()

    const handler = () => setVHProp()
    const mql = window.matchMedia('(orientation: portrait)')
    const goodBrowser = 'addEventListener' in mql
    if (goodBrowser) mql.addEventListener('change', handler)
    else mql.addListener(handler)

    return () => {
      if (goodBrowser) mql.removeEventListener('change', handler)
      else mql.removeListener(handler)
    }
  }, [setVHProp])
  useEventListener('resize', setVHProp)

  const _visitor = controlPoint
    ? controlPoint.visitors.edges.find(
        ({ node: { user: visitor } }) => visitor.id === user.id
      )
    : null
  const _visitedAt = _visitor
    ? moment(_visitor.node.visitedAt).format('LLL')
    : null

  const findInMap = () => {
    const { latitude, longitude } = controlPoint
    history.push('/map', { latitude, longitude })
  }

  function checkQR(evt) {
    evt.stopPropagation()
    if (!qrRef.current) return

    qrRef.current.click()
  }

  function qrReadSuccess(result) {
    let href = result.result.startsWith('https') ? result.result : null
    if (process.env.NODE_ENV === 'development')
      href = href.replace(/^https/, 'http')

    if (href) window.location.href = href
    else console.error(translations.errorInQR)
  }
  function qrReadError(err) {
    console.error(err)
    console.error(translations.couldNotReadQR)
  }

  return (
    <Wrapper open={open}>
      <PoseGroup>
        {open && [
          <Shade key="shade" onClick={onClose} />,
          <Modal key="modal">
            <button type="button" className="close" onClick={onClose}>
              <Icon icon="times" />
            </button>
            <ModalInner>
              <div className="image">
                <img
                  src={controlPoint.image ?? controlPoint.provider?.image}
                  alt={controlPoint.name}
                />
              </div>
              <div className="info">
                {controlPoint.significant && (
                  <DetectClickOutside
                    active
                    onClickOutside={() => setShowSignificantText(false)}
                  >
                    <div className="significant">
                      {significantControlPointIcon ? (
                        <img
                          className="icon custom"
                          src={significantControlPointIcon}
                          alt="star"
                          onClick={() => setShowSignificantText(true)}
                        />
                      ) : (
                        <Icon
                          className="icon star"
                          solid
                          icon="star"
                          color="creamcan"
                          onClick={() => setShowSignificantText(true)}
                        />
                      )}

                      {significantControlPointText && (
                        <SignificantText
                          className={showSignificantText ? 'visible' : ''}
                        >
                          {significantControlPointText}
                        </SignificantText>
                      )}
                    </div>
                  </DetectClickOutside>
                )}
                <TitleSection>
                  <PrimaryInfo>
                    {controlPoint.provider && (
                      <span className="provider">
                        {controlPoint.provider.name}
                      </span>
                    )}
                    <h2>
                      {controlPoint.name}
                      <Visit visited={!!_visitedAt}>
                        {_visitedAt
                          ? translations.visited
                          : translations.notVisited}
                        {!!_visitedAt && <span>{_visitedAt}</span>}
                      </Visit>
                    </h2>
                    <p className="address">{controlPoint.address}</p>
                    {(controlPoint.openingTime || controlPoint.closingTime) && (
                      <div className="opening-times">
                        <dl>
                          <dt>{translations.opening}:</dt>
                          <dd>
                            {controlPoint.openingTime || translations.unknown}
                          </dd>

                          <dt>{translations.closing}:</dt>
                          <dd>
                            {controlPoint.closingTime || translations.unknown}
                          </dd>
                        </dl>
                      </div>
                    )}
                  </PrimaryInfo>
                  <VisitButtonWrapper>
                    <QRReader
                      hidden
                      ref={qrRef}
                      onSuccess={qrReadSuccess}
                      onError={qrReadError}
                    />
                    <RegisterVisitButton onClick={checkQR}>
                      {translations.registerVisit}
                    </RegisterVisitButton>
                  </VisitButtonWrapper>
                </TitleSection>
                {findInMapButton && (
                  <button className="find-in-map" onClick={findInMap}>
                    {translations.findInMap}
                  </button>
                )}
                <p className="desc">
                  {controlPoint.description ||
                    controlPoint.provider?.description ||
                    translations.noDescription}
                </p>
              </div>
              <div className="bonuses">
                <ControlPointBonusList controlPointBonuses={bonuses} />
              </div>
            </ModalInner>
          </Modal>,
        ]}
      </PoseGroup>
    </Wrapper>
  )
}

ControlPointPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  controlPoint: PropTypes.object,

  findInMapButton: PropTypes.bool,

  onClose: PropTypes.func.isRequired,
}
ControlPointPopup.defaultProps = {
  findInMapButton: false,
}

export default ControlPointPopup
