import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import posed, { Transition } from 'react-pose'
import { cssSize } from 'util/prop-type-validators'
import { overloadColor } from 'util/style'

import Icon from 'components/Icon'
import Button from './Button'

const CButton = styled(Button)`
  padding-left: ${({height, checkBoxSize}) => `calc((${height} - ${checkBoxSize}) / 2)`};
`
const CheckMark = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.checkBoxSize};
  height: ${props => props.checkBoxSize};
  margin-right: 8px;
  background: ${props => overloadColor(props.checkBoxBackground)};

  i {
    margin-left: 1px;
    margin-top: 2px;
  }
`
const Check = posed(Icon)({
  enter: { scale: 1 },
  exit: { scale: 0 }
})

function CheckButton({
  className,
  children,
  checked,

  height,

  checkBoxSize,
  checkBoxBackground,
  checkColor,

  disabled,

  onClick,

  ...rest
}) {
  return (
    <CButton
      className={className}
      type="button"
      role="checkbox"
      aria-checked={checked}

      height={height}
      checkBoxSize={checkBoxSize}

      disabled={disabled}

      {...rest}

      onClick={onClick}
    >
      <CheckMark
        checkBoxSize={checkBoxSize}
        checkBoxBackground={checkBoxBackground}
      >
        <Transition>
          {checked && <Check key="checked" icon="check" size="1.4em" color={checkColor} />}
        </Transition>
      </CheckMark>
      {children}
    </CButton>
  )
}

CheckButton.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,

  height: cssSize,

  checkBoxSize: cssSize,
  checkBoxBackground: PropTypes.string,
  checkColor: PropTypes.string,

  disabled: PropTypes.bool,

  onClick: PropTypes.func,
}
CheckButton.defaultProps = {
  checked: false,

  height: '36px',

  checkBoxSize: '24px',
  checkBoxBackground: 'white',
  checkColor: 'rgba(0, 0, 0, 0.5)',

  disabled: false,

  onClick: () => void 0,
}

export default CheckButton
