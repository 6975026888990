import React from 'react'
import styled from 'styled-components'
import UrLogo from 'assets/images/ur-logo.png'
import { useIntl } from 'util/hooks'

const Wrapper = styled.footer`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
  height: 24px;

  a {
    display: grid;
    grid-template-columns: auto auto;
    gap: 6px;
    text-decoration: none;

    span {
      align-self: center;
      font-size: 0.6rem;
      text-transform: uppercase;
      color: ${props => props.theme.colors.pinecone};
    }
    img {
      height: 16px;
      opacity: 0.5;
      filter: grayscale(1);

      transition: all 0.5s linear;

      &:hover {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
`

function UrFooter() {
  const intl = useIntl()
  const translations = {
    createdBy: intl.fm('common.created-by'),
  }

  return (
    <Wrapper>
      <a
        href="https://ursolutions.no/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{translations.createdBy}</span>
        <img src={UrLogo} alt="UR Solutions AS" />
      </a>
    </Wrapper>
  )
}

export default UrFooter
