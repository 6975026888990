import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getLoginToken } from 'modules/login/util'
import { createUploadLink } from 'apollo-upload-client'
import { BACKEND_URL } from 'util/env'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLoginToken()
  const href = window.location.href

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token} ${href}` : href,
    },
  }
})
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message))
})
const uploadLink = createUploadLink({
  uri: BACKEND_URL,
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(uploadLink)]),
  cache: new InMemoryCache(),
})

export default client
