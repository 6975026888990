import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { CURRENT_EVENT_QUERY } from 'modules/events/queries'

import { EventDoesNotExist } from 'modules/events'
import { EventContext } from 'context'

import FullLoader from 'components/Loader/FullLoader'
import CenteredErrorMessage from 'components/CenteredErrorMessage'

import NoEventRoutes from './NoEventRoutes'
import Routes from './Routes'

const eventBasePathRegex = /^\/e\/[\w-]+/

export default function Bootstrap() {
  const match = window.location.pathname.match(eventBasePathRegex)

  const { loading, data, error } = useQuery(CURRENT_EVENT_QUERY, {
    skip: !match,
  })

  if (!match) return <NoEventRoutes />

  if (loading) return <FullLoader text="Laster" />
  if (error || !data) return <CenteredErrorMessage />

  const event = data.currentEvent
  if (event === null) return <EventDoesNotExist />

  document.title = event.name

  return (
    <EventContext.Provider value={event}>
      <Routes />
    </EventContext.Provider>
  )
}
