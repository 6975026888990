import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { overloadColorProp } from 'util/style'
import { cssSize } from 'util/prop-type-validators'

import Loader from './Loader'

const FullLoaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: ${props => props.pointerEvents};
`


const Overlay = styled.div`
  opacity: ${props => props.opacity};
  background-color: ${props => overloadColorProp(props)};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Inner = styled.div`
  position: relative;
  margin-top: -6em;
  z-index: 2;

  ${props => props.text && `
    &::after {
      content: '${props.text}';
      font-size: ${props.fontSize};

      position: absolute;
      left: 50%;
      bottom: -4rem;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  `}
`

export default function FullLoader({
  color,
  size,
  overlayColor,
  overlayOpacity,
  loaderOpacity,
  pointerEvents,
  text,
  fontSize
}) {
  return (
    <FullLoaderWrapper
      pointerEvents={pointerEvents}
    >
      <Inner
        text={text}
        fontSize={fontSize}
      >
        <Loader size={size} color={color} opacity={loaderOpacity} />
      </Inner>
      {/* Overlay besides Inner, so their opacities are not affecting each other */}
      <Overlay
        color={overlayColor}
        opacity={overlayOpacity}
      />
    </FullLoaderWrapper>
  )
}


FullLoader.propTypes = {
  color: PropTypes.string,
  loaderSize: PropTypes.number,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
  loaderOpacity: PropTypes.number,
  pointerEvents: PropTypes.string,
  text: PropTypes.string,
  fontSize: cssSize
}

FullLoader.defaultProps = {
  size: 80,
  overlayColor: 'white',
  overlayOpacity: 0,
  loaderOpacity: 0.5,
  pointerEvents: 'all',
  fontSize: '1em'
}
