import { useEffect } from 'react'
import { withLeaflet } from 'react-leaflet'
import Locate from 'leaflet.locatecontrol'
import { useRef } from 'react'

function LocateControl({ leaflet: { map }, options, startDirectly }) {
  const initialMount = useRef(true)

  useEffect(() => {
    if (!initialMount.current) return
    initialMount.current = false

    const lc = new Locate(options)
    lc.addTo(map)

    if (startDirectly) {
      lc.start()
    }
  }, [options, map, startDirectly])

  return null
}

export default withLeaflet(LocateControl)
