import styled from 'styled-components'
import PropTypes from 'prop-types'

const Label = styled.label`
  display: ${props => props.inline ? 'inline-block' : 'block'};
  padding-right: ${props => props.inline ? '1em' : '0'};
  text-align: left;
  color: ${props => props.theme.colors.lightgray};
  margin-bottom: 5px;
  font-weight: ${props => props.bold && 'bold'};

  ${props => props.labelStyle}
`

Label.propTypes = {
  bold: PropTypes.bool,
  labelStyle: PropTypes.any
}

export default Label
