import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useIntl } from 'util/hooks'
import { passwordStrength } from 'util/helpers'

const Wrapper = styled.div``
const Text = styled.div`
  margin-bottom: 6px;
  font-size: 0.8rem;
  text-transform: uppercase;

  span.label {
    color: ${props => props.theme.colors.americano};
  }
`
const Meter = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4px;
`
const Level = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${props => (props.active ? props.color : '#cacaca')};
`

function PasswordStrength({ password, minLength = 6, maxLength = 128 }) {
  const intl = useIntl()
  const translations = {
    strength: intl.fm('common.strength'),
    invalid: intl.fm('common.invalid'),
    weak: intl.fm('common.weak'),
    medium: intl.fm('common.medium'),
    strong: intl.fm('common.strong'),
  }
  const [strength, strengthText] = useMemo(() => {
    let strn = 0
    const strings = [
      translations.invalid,
      translations.weak,
      translations.medium,
      translations.strong,
    ]
    let text = strings[0]

    strn = passwordStrength(password, minLength, maxLength)
    text = strings[strn]

    return [strn, text]
  }, [
    translations.invalid,
    translations.weak,
    translations.medium,
    translations.strong,
    password,
    minLength,
    maxLength,
  ])

  return (
    <Wrapper>
      <Text>
        <span className="label">{translations.strength}:</span>&nbsp;
        <span>{strengthText}</span>
      </Text>
      <Meter>
        <Level active={strength > 0} color="#d61118" />
        <Level active={strength > 1} color="#d8db18" />
        <Level active={strength > 2} color="#08a10d" />
      </Meter>
    </Wrapper>
  )
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
}

export default PasswordStrength
