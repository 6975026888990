import { useQuery } from '@apollo/react-hooks'
import CenteredErrorMessage from 'components/CenteredErrorMessage'
import { FullLoader } from 'components/Loader'
import { removeLoginToken } from 'modules/login/util'
import moment from 'moment'
import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components'
import { useIntl } from 'util/hooks'
import EventCard from './EventCard'
import { ALL_EVENTS_QUERY } from './queries'

const Wrapper = styled.div`
  padding: 1.6rem;
`

const Cards = posed(styled.div`
  display: flex;
  flex-direction: column;

  perspective: 600px;

  h4 {
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.pinecone};
    margin: 12px 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
  p.no-events {
    text-align: center;
    font-size: 0.8rem;
    margin: 0 0 12px;
  }
`)({
  enter: {
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 100,
  },
  exit: {
    opacity: 0,
    delay: 300,
    staggerChildren: 100,
    staggerDirection: -1,
  },
})
const LogoutWrapper = styled.div`
  margin: 2rem;
  padding-top: 1rem;

  border-top: 1px solid ${props => props.theme.colors.greensmoke};
  text-align: center;

  span {
    color: ${props => props.theme.colors.killarney};
  }
`

function SelectEvent() {
  const intl = useIntl()
  const translations = {
    loading: intl.fm('events.loading'),
    myEvents: intl.fm('events.my-events'),
    otherEvents: intl.fm('events.other-events'),
    noUserEvents: intl.fm('events.no-user-events'),
    noOtherEvents: intl.fm('events.no-other-events'),
    logout: intl.fm('common.logout'),
  }

  const { loading, error, data } = useQuery(ALL_EVENTS_QUERY)

  if (error) return <CenteredErrorMessage />

  if (loading) {
    return <FullLoader text={translations.loading} />
  }
  if (error || !data) {
    return <CenteredErrorMessage />
  }

  const events = data.allEvents.edges.map(edge => edge.node)
  const myEvents = events.filter(_event => _event.participating)
  const otherEvents = events.filter(
    _event =>
      !_event.participating &&
      (_event.dateEnd === null ||
        moment().diff(moment(_event.dateEnd), 'seconds') < 0)
  )

  function doLogout() {
    removeLoginToken()
    window.location.href = '/'
  }

  // TODO what to do with forfeited events?

  return (
    <Wrapper>
      <PoseGroup>
        {!loading && (
          <Cards key="cards">
            <h4>{translations.myEvents}</h4>
            {myEvents.length ? (
              myEvents.map(event => (
                <EventCard key={event.id} event={event} userEvent />
              ))
            ) : (
              <p className="no-events">{translations.noUserEvents}</p>
            )}
            <h4>{translations.otherEvents}</h4>
            {otherEvents.length ? (
              otherEvents.map(event => (
                <EventCard key={event.id} event={event} />
              ))
            ) : (
              <p className="no-events">{translations.noOtherEvents}</p>
            )}
          </Cards>
        )}
      </PoseGroup>

      <LogoutWrapper>
        <span onClick={() => doLogout()}>{translations.logout}</span>
      </LogoutWrapper>
    </Wrapper>
  )
}

export default SelectEvent
