import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { IntlProvider } from 'react-intl'

import moment from 'moment'
import messages from 'locale/messages'

import Bootstrap from './Bootstrap'
import theme from '../theme'
import client from '../apollo'

import ToastContainer from 'modules/toasts'

export default function Root() {
  moment.locale('nb')

  return (
    <ApolloProvider client={client}>
      <IntlProvider locale="nb" messages={messages['nb']}>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <Bootstrap />
            <ToastContainer></ToastContainer>
          </React.Fragment>
        </ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  )
}
