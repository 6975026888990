import { useMutation, useQuery } from '@apollo/react-hooks'
import Icon from 'components/Icon'
import Loader, { FullLoader } from 'components/Loader'
import QRReader from 'components/QRReader'
import L from 'leaflet'
import { RESEND_CONFIRMATION_HASH_QUERY } from 'modules/login/queries'
import ControlPointPopup from 'modules/points/ControlPointPopup'
import { CREATE_CONTROL_POINT_VISIT_MUTATIONS } from 'modules/points/mutations'
import { distance } from 'modules/points/util'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { view } from 'react-easy-state'
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet'
import posed, { PoseGroup } from 'react-pose'
import { useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import { store, toasts } from 'store'
import styled from 'styled-components'
import { DEBUG } from 'util/env'
import { useEvent, useIntl, useRouter, useUser, useSound } from 'util/hooks'
import { buttonUnset } from 'util/style'
import LocateControl from './LocateControl'
import ConfirmSound from 'assets/sounds/confirmVisit.mp3'
import queryString from 'query-string'

const Wrapper = styled.div`
  height: calc(100vh - 52px);

  .leaflet-container {
    width: 100vw;
    height: 100%;

    max-height: calc(100vh - 52px);

    .leaflet-control-locate {
      border-radius: 50%;
      overflow: hidden;
      border-color: rgba(110, 98, 89, 0.8);
      transform: translateY(-10px);

      a {
        background: rgba(233, 230, 228, 0.5);
        color: white;

        i.fa {
          color: ${props => props.theme.colors.pinecone};
        }
      }
    }
    .marker {
      img {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
        object-fit: cover;
        background-color: ${props => props.theme.colors.desertstorm};
        border-radius: 50%;
        border: 3px solid ${props => props.theme.colors.americano};
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3)) grayscale(50%);
        transition: all 0.5s ease-out;
      }
      &.visited img {
        border-width: 4px;
        border-color: ${props => props.theme.colors.apple};
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
      }
    }
  }
`
const CheckDistance = styled.button`
  ${buttonUnset}

  position: absolute;
  z-index: 789;
  bottom: 37px;
  left: 50%;
  transform: translateX(-50%);

  border: 2px solid rgba(110, 98, 89, 0.8);
  border-radius: 8px;
  background: rgba(233, 230, 228, 0.8);

  color: ${props => props.theme.colors.pinecone};
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;

  padding: 1rem 1.2rem;

  &:active {
    filter: brightness(0.8);
  }
`
const NotRegisteredModal = posed(styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 30vh;
  left: 4vw;
  width: 92vw;
  z-index: 10001;
  overflow: hidden;

  padding: 1rem;
  background: white;
  border-radius: 4px;

  h4 {
    margin: 0 0 8px 0;
    font-weight: bold;
  }
  span {
    color: ${props => props.theme.colors.killarney};
    text-decoration: underline;
  }
  div.loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
  }
`)({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
})
const Shade = posed(styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);
`)({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

const BoringChooseMethodPopup = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 9000;
  top: -12px;
  left: -2px;
  width: calc(100% + 4px);
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.colors.pinecone};
  font-size: 0.8rem;

  div.button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;

    border: 2px solid rgba(110, 98, 89, 0.8);
    border-radius: 999px;
    background: rgba(233, 230, 228, 0.8);

    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
  }
`
const ChooseMethodPopup = animated(BoringChooseMethodPopup)

function Map() {
  const initialMount = useRef(true)
  const qrRef = useRef(null)

  useEffect(() => {
    store.headerShadowOverride = true

    return () => {
      store.headerShadowOverride = false
    }
  }, [])

  const playConfirmation = useSound(ConfirmSound)
  const user = useUser()
  const event = useEvent()
  const location = useLocation()

  const parsedQueryString = queryString.parse(location.search)

  const initialPointId = parsedQueryString.point ?? null
  const initialVisited = !parsedQueryString.visited
    ? false
    : parsedQueryString.visited === 'true'

  const eventSettings = event.eventSettings.edges
    .map(({ node }) => node)
    .reduce((_settings, { key, value }) => (_settings[key] = value), {})
  const MAXIMUM_POINT_DISTANCE =
    parseFloat(eventSettings['maximum-point-distance'] || 50) / 1000

  const lastKnownLocation = useRef(null)

  const [popupOpen, setPopupOpen] = useState(false)
  const [popupControlPoint, setPopupControlPoint] = useState(null)
  const [showNotRegisteredModal, setShowNotRegisteredModal] = useState(false)
  // const [controlPoints, setControlPoints] = useState([])
  const [initialCoordinates, setInitialCoordinates] = useState([
    59.9133861,
    10.751924,
  ])
  const [initialZoom, setInitialZoom] = useState(13)
  const [startDirectly, setStartDirectly] = useState(false)
  const [chooseMethodPopupOpen, setChooseMethodPopupOpen] = useState(false)

  const {
    location: { state },
  } = useRouter()

  const { loading: resendHashLoading, refetch: refetchHash } = useQuery(
    RESEND_CONFIRMATION_HASH_QUERY,
    {
      skip: true,
    }
  )

  useEffect(() => {
    if (!initialMount.current) return
    initialMount.current = false

    if (
      state &&
      typeof state.latitude !== 'undefined' &&
      typeof state.longitude !== 'undefined'
    ) {
      const { latitude, longitude } = state
      setInitialCoordinates([latitude, longitude])
      setInitialZoom(17)
      return
    } else if (event.latitude !== null && event.longitude !== null) {
      setInitialCoordinates([event.latitude, event.longitude])
    }
    setStartDirectly(true)
  }, [event.latitude, event.longitude, state])

  const controlPoints = event.controlPoints.edges.map(edge => edge.node)

  useEffect(() => {
    if (popupControlPoint) {
      const newCP = controlPoints.find(({ id }) => id === popupControlPoint.id)
      if (newCP) {
        setPopupControlPoint(newCP)
      }
    }
  }, [controlPoints, popupControlPoint])

  const [
    createControlPointVisitMutation,
    { loading: createControlPointVisitLoading },
  ] = useMutation(CREATE_CONTROL_POINT_VISIT_MUTATIONS, {
    refetchQueries: ['CurrentEvent', 'Me'],
    awaitRefetchQueries: true,
    onCompleted(data) {
      const {
        createControlPointVisit: {
          controlPointVisit: {
            controlPoint: { id: cpId },
          },
        },
      } = data
      const cp = controlPoints.find(_cp => _cp.id === cpId)

      if (cp) {
        setPopupControlPoint(cp)
        setPopupOpen(true)
      }
    },
    onError(err) {
      if (DEBUG) console.error(err)
    },
  })

  const intl = useIntl()
  const translations = {
    centerOnMyPosition: intl.fm('map.center-on-my-position'),
    couldNotFindPosition: intl.fm('map.could-not-find-position'),
    registerVisit: intl.fm('map.register-visit'),
    checkDistanceError: intl.fm('map.check-distance-error'),
    notCloseEnough: intl.fm('map.not-close-enough'),
    closestNoneUnvisited: intl.fm('map.closest-none-unvisited'),
    visitRegisteredSuccess: name =>
      intl.fm('map.visit-registered-success', null, { name }),
    visitRegisteredError: name =>
      intl.fm('map.visit-registered-error', null, { name }),
    eventNotStarted: intl.fm('map.event-not-started', null, {
      dateStart: moment(event.dateStart).format('LLL'),
    }),
    eventEnded: intl.fm('map.event-ended'),

    alreadyVisited: intl.fm('map.already-visited'),
    visitByQrSuccess: intl.fm('map.visit-by-qr-success'),
    qrCodeNotFound: intl.fm('map.qr-code-not-found'),
    couldNotReadQr: intl.fm('map.could-not-read-qr'),
    errorInQR: intl.fm('map.error-in-qr'),

    notActivated: intl.fm('map.not-activated'),
    notActivatedText: intl.fm('map.not-activated-text', null, {
      a: txt => <span onClick={() => resendConfirmationHash()}>{txt}</span>,
    }),

    resentConfirmationHash: email =>
      intl.fm('users.resent-confirmation-hash', null, { email }),
    resendConfirmationHashError: intl.fm(
      'users.resend-confirmation-hash-error'
    ),
  }

  const popup = cp => {
    setPopupControlPoint(cp)
    setPopupOpen(true)
  }
  const closePopup = () => {
    setPopupOpen(false)
    setPopupControlPoint(null)
  }

  const locateOptions = {
    position: 'bottomright',
    strings: {
      title: translations.centerOnMyPosition,
    },
    clickBehavior: {
      inView: 'setView',
      outOfView: 'setView',
      inViewNotFollowing: 'setView',
    },
    iconElementTag: 'i',
    flyTo: true,
    enableHighAccuracy: true,
    onActivate: () => {},
  }
  const httpsify = url => {
    // Because dev server doesn't support HTTPS
    if (process.env.NODE_ENV === 'development') return url
    // Not on dev
    return url.replace(/^http:/, 'https:')
  }

  const createMarker = point => {
    const _visited = hasVisited(point)
    const thumb = point.thumbnail
      ? httpsify(point.thumbnail)
      : point.provider?.image
      ? httpsify(point.provider.image)
      : null

    return L.divIcon({
      className: `marker ${_visited && 'visited'}`,
      html: thumb
        ? `
        <img src="${thumb}" alt="thumb" />
      `
        : '<img />',
    })
  }

  const resendConfirmationHash = async () => {
    try {
      const data = await refetchHash()
      const {
        data: {
          resendConfirmationHash: {
            ok,
            user: { email },
          },
        },
      } = data
      if (!ok) throw new Error('Unknown error')

      setShowNotRegisteredModal(false)
      toasts.addToast('success', translations.resentConfirmationHash(email))
    } catch (err) {
      console.error(err)
      toasts.addToast('error', translations.resendConfirmationHashError)
    }
  }

  const hasVisited = cp => {
    const _visitor = cp.visitors.edges.find(
      ({ node: { user: visitor } }) => visitor.id === user.id
    )
    return !!(_visitor && _visitor.node.visitedAt)
  }
  const locationFound = ({ latitude, longitude }) => {
    lastKnownLocation.current = [latitude, longitude]
  }
  const eventHasStarted = () => {
    const now = moment()
    const start = moment(event.dateStart)
    return now.diff(start) > 0
  }
  const eventHasEnded = () => {
    const now = moment()
    const end = moment(event.dateEnd)
    return now.diff(end) > 0
  }
  const checkDistance = async evt => {
    evt.stopPropagation()
    setChooseMethodPopupOpen(false)

    if (!user.hasRegistered) {
      setShowNotRegisteredModal(true)
      return
    }

    if (!eventHasStarted()) {
      toasts.addToast('info', translations.eventNotStarted)
      return
    } else if (eventHasEnded()) {
      toasts.addToast('info', translations.eventEnded)
      return
    }

    if (!lastKnownLocation.current) {
      toasts.addToast('error', translations.checkDistanceError)
      return
    }

    const [curLat, curLon] = lastKnownLocation.current
    const distances = controlPoints.map(point => ({
      point,
      distance: distance(curLat, curLon, point.latitude, point.longitude),
    }))

    const closeEnough = distances.filter(
      dist => dist.distance < MAXIMUM_POINT_DISTANCE
    )
    if (!closeEnough.length) {
      toasts.addToast('info', translations.notCloseEnough)
      return
    }

    const unvisited = closeEnough.filter(({ point }) => !hasVisited(point))
    if (!unvisited.length) {
      toasts.addToast('info', translations.closestNoneUnvisited)
      return
    }

    const closest = unvisited.reduce(
      (acc, cur) => (cur.distance < acc.distance ? cur : acc),
      unvisited[0]
    )
    const response = await createControlPointVisitMutation({
      variables: {
        input: {
          controlPoint: closest.point.id,
          visitedAt: new Date(),
        },
      },
    })
    if (!response) {
      toasts.addToast(
        'error',
        translations.visitRegisteredError(closest.point.name)
      )
    } else {
      playConfirmation()
      toasts.addToast(
        'success',
        translations.visitRegisteredSuccess(closest.point.name)
      )
    }
  }

  const checkQR = evt => {
    evt.stopPropagation()
    if (!qrRef.current) return

    qrRef.current.click()
    setChooseMethodPopupOpen(false)
  }

  const methodTransition = useTransition(chooseMethodPopupOpen, {
    from: {
      opacity: 0,
      transform: 'translateY(0%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(-100%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(0%)',
    },
  })

  useLayoutEffect(() => {
    if (!initialPointId || controlPoints.length === 0) return
    const point = controlPoints.find(cp => cp.id === initialPointId)
    if (!point) return

    popup(point)
    window.history.replaceState(state, '', window.location.href.split('?')[0])

    setTimeout(() => {
      if (initialVisited) toasts.addToast('info', translations.alreadyVisited)
      else {
        toasts.addToast('success', translations.visitByQrSuccess)
        playConfirmation()
      }
    }, 1000)
    // eslint-disable-next-line
  }, [initialPointId, controlPoints.length])

  function qrReadSuccess(result) {
    let href = result.result.startsWith('https') ? result.result : null
    if (process.env.NODE_ENV === 'development')
      href = href.replace(/^https/, 'http')

    if (href) window.location.href = href
    else toasts.addToast('error', translations.errorInQR)
  }
  function qrReadError(err) {
    console.error(err)
    toasts.addToast('error', translations.couldNotReadQR)
  }

  return (
    <>
      {createControlPointVisitLoading && <FullLoader overlayOpacity={0.5} />}

      <PoseGroup>
        {showNotRegisteredModal && [
          <Shade
            key="not-registered-shade"
            onClick={() => setShowNotRegisteredModal(false)}
          />,
          <NotRegisteredModal key="not-registered-modal">
            <h4>{translations.notActivated}</h4>
            {translations.notActivatedText}
            {resendHashLoading && (
              <div className="loading">
                <Loader size={48} thickness={4} />
              </div>
            )}
          </NotRegisteredModal>,
        ]}
      </PoseGroup>

      <ControlPointPopup
        open={popupOpen}
        controlPoint={popupControlPoint}
        onClose={closePopup}
      />

      <Wrapper>
        <LeafletMap
          center={initialCoordinates}
          zoom={initialZoom}
          maxZoom={20}
          attributionControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          zoomControl={false}
          easeLinearity={0.35}
          onLocationFound={locationFound}
          onLocationError={() => {
            toasts.addToast('warning', translations.couldNotFindPosition)
          }}
        >
          <LocateControl
            options={locateOptions}
            startDirectly={startDirectly}
          />
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>'
          />
          {controlPoints.map(cp => (
            <Marker
              key={cp.id}
              icon={createMarker(cp)}
              position={[cp.latitude, cp.longitude]}
              onClick={() => popup(cp)}
            />
          ))}
        </LeafletMap>

        <QRReader
          hidden
          ref={qrRef}
          onSuccess={qrReadSuccess}
          onError={qrReadError}
        />
        <CheckDistance
          onClick={e => {
            if (event.pointVisitMethods === 'gps') checkDistance(e)
            else if (event.pointVisitMethods === 'qr') checkQR(e)
            else setChooseMethodPopupOpen(!chooseMethodPopupOpen)
          }}
        >
          {event.pointVisitMethods === 'both' &&
            methodTransition(
              (values, open) =>
                open && (
                  <ChooseMethodPopup style={values}>
                    <div className="button" onClick={checkDistance}>
                      <Icon icon="map-marker" color="pinecone" size="1.2rem" />
                    </div>
                    <div className="button" onClick={checkQR}>
                      <Icon icon="qrcode" color="pinecone" size="1.2rem" />
                    </div>
                  </ChooseMethodPopup>
                )
            )}

          {translations.registerVisit}
        </CheckDistance>
      </Wrapper>
    </>
  )
}

export default view(Map)
