import gql from 'graphql-tag'

export const ControlPointFragments = {
  info: gql`
    fragment ControlPointInfo on ControlPointNode {
      id
      name
      description
      significant
      contactPhoneNumber
      contactEmail
      visitMethod
      image
      thumbnail
      openingTime
      closingTime
      address
      latitude
      longitude
      provider {
        id
        name
        description
        image
      }
      visitors {
        edges {
          node {
            id
            visitedAt
            user {
              id
            }
          }
        }
      }
      bonuses {
        edges {
          node {
            id
            name
            image
            description
            validFrom
            validUntil
            userBonuses {
              edges {
                node {
                  id
                  user {
                    id
                  }
                  usedAt
                }
              }
            }
          }
        }
      }
    }
  `,
}
