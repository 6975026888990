import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import posed, { PoseGroup } from 'react-pose'

import { useMutation } from '@apollo/react-hooks'
import { IS_LOGGED_IN_QUERY } from './queries'
import { LOGIN_MUTATION } from './mutations'

import { useIntl, useRouter } from 'util/hooks'
import { setLoginToken, getRememberMe, setFirstLogin } from './util'
import client from 'apollo'

import Input from 'components/Input'
import Button, { CheckButton } from 'components/Button'
import Icon from 'components/Icon'

import logo from 'assets/images/cultura-logo-2.png'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 1rem;

  p {
    font-size: 14px;
    text-align: center;
    color: ${props => props.theme.colors.killarney};
  }
`
const Logo = styled.div`
  width: 80vw;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`
const Error = styled.div`
  position: relative;
`
const ErrorMessage = posed(styled.div`
  position: absolute;
  bottom: 4px;

  font-size: 0.8rem;
  color: ${props => props.theme.colors.error};
`)({
  enter: {
    scale: 1,
    y: 0,
    originX: 'left',
    originY: 'top',
  },
  exit: {
    scale: 0,
    y: '100%',
    originX: 'left',
    originY: 'bottom',
  },
})
const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'email    email'
    'password password'
    'remember submit'
    'register forgot';
  grid-gap: 12px;
`
const Email = styled.div`
  grid-area: email;
`
const Password = styled.div`
  grid-area: password;
`
const RememberButton = styled(CheckButton)`
  grid-area: remember;
`
const SubmitButton = styled(Button)`
  grid-area: submit;
`
const Register = styled.div`
  grid-area: register;
  font-size: 0.8rem;

  a {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: solid ${props => props.theme.colors.killarney};
    border-width: 0 0 1px 0;
    padding: 2px 0;

    span {
      margin-right: 1ch;
      font-size: 0.8rem;
      color: ${props => props.theme.colors.killarney};
    }
  }
`
const Forgot = styled.div`
  grid-area: forgot;
  font-size: 0.8rem;

  a {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: solid ${props => props.theme.colors.killarney};
    border-width: 0 0 1px 0;
    padding: 2px 0;
  }
`

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(getRememberMe())
  const [error, setError] = useState(null)

  const { history } = useRouter()
  const next = history.location?.state?.next ?? null

  const [loginMutation, { loading: loginLoading }] = useMutation(
    LOGIN_MUTATION,
    {
      onCompleted: data => {
        if (!data.login.ok) {
          setError(translations.wrongEmailPassword)
          return
        }

        const token = data.login.token
        setLoginToken(token, remember)

        if (data.login.firstLogin) setFirstLogin()

        client.writeQuery({
          query: IS_LOGGED_IN_QUERY,
          data: {
            isLoggedIn: true,
          },
        })

        const needsPasswordChange = !!data.login.user?.needsPasswordChange

        if (next) window.location.href = next
        else if (needsPasswordChange) history.push('/change-password')
        else history.push('/')
      },
      onError: () => {
        setError(translations.serverError)
      },
    }
  )

  const intl = useIntl()
  const translations = {
    email: intl.fm('common.email'),
    password: intl.fm('common.password'),
    rememberMe: intl.fm('login.remember-me'),
    wrongEmailPassword: intl.fm('login.wrong-email-password'),
    serverError: intl.fm('server.general-error-try-again-later'),
    signingIn: intl.fm('login.signing-in'),
    newUser: intl.fm('users.new-user'),
  }

  const login = () => {
    setError(null)
    loginMutation({
      variables: {
        email,
        password,
      },
    })
  }

  return (
    <Wrapper>
      <Logo>
        <img src={logo} alt="Cultura logo" />
      </Logo>

      <p>
        På Økojakt kan du bli kjent med byens økologiske perler. Du samler poeng
        for hvert besøk, får gode tilbud og kan vinne flotte premier. Registrer
        deg her og bli med på Økojakt!
      </p>
      <p>Support: cultura@cultura.no</p>

      <Error>
        <PoseGroup>
          {error && <ErrorMessage key="error">{error}</ErrorMessage>}
        </PoseGroup>
      </Error>
      <FormWrapper>
        <Email>
          <Input
            value={email}
            fullWidth
            placeholder={translations.email}
            inputMode="email"
            disabled={loginLoading}
            onChange={evt => setEmail(evt.target.value)}
          />
        </Email>

        <Password>
          <Input
            value={password}
            type="password"
            passwordVisibilityToggle
            fullWidth
            placeholder={translations.password}
            autoCorrect="false"
            autoCapitalize="none"
            disabled={loginLoading}
            onChange={evt => setPassword(evt.target.value)}
          />
        </Password>

        <RememberButton
          primary
          checked={remember}
          checkBoxBackground="pineglade"
          disabled={loginLoading}
          onClick={() => setRemember(!remember)}
        >
          {translations.rememberMe}
        </RememberButton>

        <SubmitButton
          primary
          fullWidth
          disabled={loginLoading}
          isLoading={loginLoading}
          loadingIntlId="login.signing-in"
          onClick={login}
        >
          <FormattedMessage id="login.sign-in" defaultMessage="Sign in" />
        </SubmitButton>

        <Register>
          <Link to="/register">
            <span>{translations.newUser}</span>
            <Icon icon="user-plus" />
          </Link>
        </Register>

        <Forgot>
          <Link to="/forgotten-password">
            <FormattedMessage
              id="login.forgot-password-alt"
              defaultMessage="Forgotten password"
            />
          </Link>
        </Forgot>
      </FormWrapper>
    </Wrapper>
  )
}

export default Login
