import { store } from 'react-easy-state'
import { sleep } from 'util/helpers'

export const toastStore = store({
  _toastId: 0,
  toasts: [],
  async addToast(type, message, life=3000){
    const newId = ++this._toastId
    this.toasts = this.toasts.concat([{
      id: newId,
      type,
      message
    }])

    await sleep(life)
    this.toasts = this.toasts.filter(toast => toast.id !== newId)
  }
})

export default toastStore
