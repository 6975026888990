import React from 'react'
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'

import PrivateRoute from 'containers/PrivateRoute'

import Login, { Register, ForgottenPassword, Activate } from 'modules/login'
import { SelectEvent } from 'modules/events'
import ParseQRCode from 'modules/points/ParseQRCode'
import { ChangePassword } from 'modules/users'

export default function NoEventRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/activate/:hash" component={Activate} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/qr/:qrCode" component={ParseQRCode} />
        <PrivateRoute path="/select-event" component={SelectEvent} />
        <Redirect from="" to="/select-event" />
      </Switch>
    </Router>
  )
}
