import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(username: $email, password: $password) {
      ok
      token
      firstLogin
      user {
        id
        email
        username
        isStaff
        isSuperuser
        needsPasswordChange
      }
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      ok
    }
  }
`
