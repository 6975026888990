import { DEBUG } from 'util/env'

import React, { useState } from 'react'
import styled from 'styled-components'

import { useMutation } from '@apollo/react-hooks'
import { CHANGE_PASSWORD_MUTATION } from './mutations'
import { FormattedMessage } from 'react-intl'
import { useIntl, useRouter } from 'util/hooks'
import { toasts } from 'store'

import Input from 'components/Input'
import Button from 'components/Button'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 1rem;
`
const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'header'
    'text'
    'password'
    'confirmPassword'
    'submit';
  grid-gap: 12px;

  h2 {
    grid-area: header;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  p {
    grid-area: text;
    margin: 0 0 8px;
    text-align: center;
  }
`
const FormField = styled.div`
  grid-area: ${props => props.area};
`

function ChangePassword() {
  const { history } = useRouter()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [errors, setErrors] = useState({})

  const intl = useIntl()
  const translations = {
    newPassword: intl.fm('users.new-password'),
    confirmPassword: intl.fm('users.confirm-password'),
    invalid: intl.fm('common.invalid'),

    setNewPassword: intl.fm('users.set-new-password'),
    passwordsDoNotMatch: intl.fm('users.password-do-not-match'),
    userPasswordUpdated: intl.fm('users.password-updated'),
    userPasswordUpdateError: intl.fm('users.password-update-error'),
  }

  const [changePasswordMutation, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Me'],
    onCompleted({ changePassword }) {
      if (changePassword.ok) {
        toasts.addToast('success', translations.userPasswordUpdated)
        history.push('/')
      }
      else
        setErrors({
          ...errors,
          oldPassword: translations.passwordInvalid
        })
    },
    onError(error) {
      if (DEBUG) console.error(error)
      toasts.addToast('error', translations.userPasswordUpdateError)
    }
  })

  const validate = () => {
    const _errors = {
      newPassword: !!newPassword ? (newPassword !== confirmPassword ? translations.passwordsDoNotMatch : null) : translations.invalid,
      confirmPassword: !!confirmPassword ? (newPassword !== confirmPassword ? translations.passwordsDoNotMatch : null) : translations.invalid
    }
    setErrors(_errors)
    return !Object.entries(_errors).some(([, val]) => val !== null)
  }

  const submit = () => {
    if (!validate())
      return

    changePasswordMutation({
      variables: {
        newPassword
      }
    })
  }

  return (
    <Wrapper>
      <FormWrapper>
        <h2>
          {translations.setNewPassword}
        </h2>
        <p>
          <FormattedMessage id="login.set-new-password" defaultMessage="Your password has been reset. Please create a new one." />
        </p>
        <FormField area="password">
          <Input
            name="newPassword"
            type="password"
            value={newPassword}
            error={errors.newPassword}

            passwordVisibilityToggle
            fullWidth
            required
            autoCorrect="false"
            autoComplete="none"
            placeholder={translations.newPassword}
            disabled={loading}

            onChange={evt => setNewPassword(evt.target.value)}
          />
        </FormField>

        <FormField area="confirmPassword">
          <Input
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            error={errors.confirmPassword}

            passwordVisibilityToggle
            fullWidth
            required
            autoCorrect="false"
            autoComplete="none"
            placeholder={translations.confirmPassword}
            disabled={loading}

            onChange={evt => setConfirmPassword(evt.target.value)}
          />
        </FormField>

        <FormField area="submit">
          <Button
            primary
            fullWidth
            disabled={!(!!newPassword && !!confirmPassword)}

            isLoading={loading}
            loadingIntlId="login.send-new-password"

            onClick={submit}
          >
            {translations.setNewPassword}
          </Button>
        </FormField>
      </FormWrapper>
    </Wrapper>
  )
}

ChangePassword.propTypes = {}
ChangePassword.defaultProps = {}

export default ChangePassword
