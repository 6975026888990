import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

const Wrapper = styled.div`
  display: flex;
  width: 96vw;
  height: 80px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
`

const IconWrapper = styled.div`
  width: 80px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.background};

  border-radius: 4px 0 0 4px;

  i {
    color: ${props => props.color};
  }
`

const MessageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box;
  padding: 0.25em 0.75em;
  overflow-y: auto;
  color: ${props => props.theme.colors.darkgray};
`

function getIconFromType(type){
  switch(type){
    default:
    case 'info':
      return 'fa fa-exclamation'
    case 'success':
      return 'fa fa-check'
    case 'error':
      return 'fa fa-minus-hexagon'
    case 'warning':
      return 'fa fa-exclamation-triangle'
  }
}

function getColorFromType(type){
  switch(type){
    default:
    case 'info':
    case 'warning':
    case 'success':
      return 'black'
    case 'error':
      return 'white'
  }
}

function getBackgroundFromType(type){
  switch(type){
    default:
    case 'info':
      return theme.colors.info
    case 'success':
      return theme.colors.success
    case 'error':
      return theme.colors.error
    case 'warning':
      return theme.colors.warning
  }
}

export default function Toast({
  toast
}) {
  return (
    <Wrapper>
      <IconWrapper color={getColorFromType(toast.type)} background={getBackgroundFromType(toast.type)}>
        <i className={getIconFromType(toast.type)}></i>
      </IconWrapper>
      <MessageWrapper>
        {toast.message}
      </MessageWrapper>
    </Wrapper>
  )
}
