import gql from 'graphql-tag'
import { UserFragments } from './fragments'

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $phoneNumber: String
    $newsLetter: Boolean!
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      newsLetter: $newsLetter
    ) {
      ok
      reason
    }
  }
`
export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser($user: ID, $confirmationHash: String) {
    registerUser(user: $user, confirmationHash: $confirmationHash) {
      ok
      token
      firstLogin
      user {
        ...UserInfo
      }
    }
  }
  ${UserFragments.info}
`
export const PATCH_USER_MUTATION = gql`
  mutation PatchUser($id: ID!, $input: PatchUserInput!) {
    patchUser(id: $id, input: $input) {
      user {
        id
      }
    }
  }
`
export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ok
      reason
    }
  }
`
export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($password: String!) {
    deleteUser(password: $password) {
      ok
    }
  }
`
