import styled, { css } from 'styled-components'
import posed from 'react-pose'
import { overloadColor, buttonUnset } from 'util/style'

export const Wrapper = styled.div``
export const Cards = styled.div`
  display: flex;
  flex-direction: column;

  perspective: 600px;
  padding-bottom: 4px;
`
export const BonusCard = posed(styled.section`
  box-sizing: border-box;
  position: relative;
  height: 90px;

  transform-style: preserve-3d;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  ${props =>
    !props.isActive &&
    `
    h2 {
      color: #999 !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      background: repeating-linear-gradient(
        -45deg,
        rgba(110, 98, 89, 0.3),
        rgba(110, 98, 89, 0.3) 2px,
        rgba(0, 0, 0, 0.2) 2px,
        rgba(0, 0, 0, 0.2) 6px
      );
    }
    &::after {
      content: '${props.overlay.text}';
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      z-index: 11;

      padding: 5px 8px;
      color: ${overloadColor(props.overlay.color)} !important;
      font-size: 0.6rem;
      font-weight: 900;
      background: rgba(0, 0, 0, 0.8);
      white-space: nowrap;
      border-radius: 4px;
      box-shadow: inset 0 0 3px 1px black;
    }
  `}
`)({
  flipped: {
    rotateX: 180,
    transition: { duration: 550 },
  },
  unflipped: {
    rotateX: 0,
    transition: { duration: 550 },
  },
})
export const strike = (width, height, background) => css`
  position: absolute;
  z-index: 1;
  top: calc(50% - (${height} / 2));
  width: ${width};
  height: ${height};
  background: ${background};
`
export const cardFace = css`
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  backface-visibility: hidden;
  visibility: visible;
  transform-style: preserve-3d;

  background-color: ${props => props.theme.colors.desertstorm};
`
export const Cover = styled.div`
  ${cardFace}

  display: grid;
  grid-template-columns: 90px 1fr 38px;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'image name  use'
    'image valid use'
    'image more  use';

  ${props =>
    !props.isActive &&
    `
    filter: grayscale(100%) opacity(0.7);
  `}
  overflow: hidden;

  .name {
    grid-area: name;
    overflow: hidden;
    margin: 8px;
  }
  dl {
    grid-area: valid;
    align-self: start;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1ex;

    font-size: 0.6rem;
    margin: 0 8px;

    dt,
    dd {
      margin: 0;
    }
    dt {
      text-align: right;
      font-size: 0.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.colors.americano};
    }
  }
  div.more {
    grid-area: more;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(126, 114, 109, 0.1) 75%
    );
    /* border-top: 1px solid rgba(126, 114, 109, 0.4); */

    button {
      ${buttonUnset}
      width: 100%;
      height: 100%;
      font-size: 0.6rem;
      text-transform: uppercase;
      padding: 4px;
    }
  }
`
export const Image = styled.div`
  grid-area: image;
  position: relative;
  backface-visibility: hidden;
  visibility: visible;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden;
  }
  ${props =>
    props.noImage
      ? `
    display: flex;
    justify-content: center;
    align-items: center;
  `
      : `
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset -2px 0 4px -2px rgba(0, 0, 0, 0.7);
    }
  `}
`
export const UseButton = styled.div`
  grid-area: use;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props =>
    props.isActive ? props.theme.colors.primary : props.theme.colors.error};
  box-shadow: inset 2px 0 2px -2px rgba(0, 0, 0, 0.7);

  &:active {
    background: ${props => props.theme.colors.apple};
  }
`
export const Backface = styled.div`
  ${cardFace}
  transform: rotateX(180deg);
  z-index: 2;

  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow-y: auto;

  h2 {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 4px 0;
    font-size: 1rem;
    text-align: center;

    &::before {
      content: '';
      ${props =>
        strike(
          '45px',
          '2px',
          `linear-gradient(to right, transparent, ${props.theme.colors.pinecone})`
        )}
      transform: translateX(-120%);
    }
    &::after {
      content: '';
      ${props =>
        strike(
          '45px',
          '2px',
          `linear-gradient(to left, transparent, ${props.theme.colors.pinecone})`
        )}
      transform: translateX(20%);
    }
  }
  p {
    margin: 0;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.pinecone};
    text-align: center;
    white-space: pre-wrap;
  }
`
export const ConfirmUse = styled.div`
  ${cardFace}
  transform: rotateX(180deg);
  z-index: 2;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 32px;
  grid-gap: 8px;

  padding: 8px;

  p {
    grid-column: span 2;
    align-self: center;
    margin: 0;
    font-size: 0.6rem;
    color: ${props => props.theme.colors.pinecone};
    text-align: center;
  }
  button {
    ${buttonUnset}
    display: block;
    width: 100%;
    height: 100%;

    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;

    &.cancel {
      background-color: #d6d6d6;
    }
    &.confirm {
      background-color: ${props => props.theme.colors.primary};
      color: white;
    }

    &:active {
      filter: brightness(0.8);
    }
  }
`
