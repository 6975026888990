export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function frmt(id, defaultMessage, intl) {
  return intl.formatMessage({ id, defaultMessage })
}

export function getTextWidth(text, font) {
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font

  const metrics = context.measureText(text)
  return metrics.width
}

export const isTouchDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export function htmlifyUrls(text) {
  const pattern = /(\s)(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))(\s)/gm
  const replaced = text.replace(
    pattern,
    '$1<a href="$2" target="_blank" rel="noopener noreferrer">$2</a>$5'
  )
  return { __html: replaced }
}

export function passwordStrength(password, minLength, maxLength) {
  if (password.length < minLength || password.length > maxLength) return 0

  // Create an array and push all possible values that you want in password
  const matchedCase = []
  matchedCase.push('[$@$!%*#?&]') // Special Charector
  matchedCase.push('[A-Z]') // Uppercase Alpabates
  matchedCase.push('[0-9]') // Numbers
  matchedCase.push('[a-z]') // Lowercase Alphabates

  // Check the conditions
  var ctr = 0
  for (let i = 0; i < matchedCase.length; i++)
    if (new RegExp(matchedCase[i]).test(password)) ctr++

  switch (ctr) {
    case 0:
    case 1:
    case 2:
      return 1
    case 3:
      return 2
    case 4:
      return 3
    default:
      return 0
  }
}
