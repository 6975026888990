import React from 'react'
import styled from 'styled-components'
import { view } from 'react-easy-state'
import posed, { PoseGroup } from 'react-pose'
import { toasts } from 'store'

import Toast from './Toast'

const Item = posed.aside({
  preEnter: { y: 50 },
  enter: { y: 0 },
  exit: { x: 700 }
})

const Wrapper = styled.div`
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  left: 2vw;
  z-index: 100001;

  .item:not(:last-child) {
    padding-bottom: 2vw;
  }
`

function ToastContainer() {
  return (
    <Wrapper>
      <PoseGroup
        animateOnMount
        flipMove
        preEnterPose="preEnter"
      >
        {toasts.toasts.map(toast => (
          <Item className="item" key={toast.id} data-key={toast.id}>
            <Toast toast={toast}/>
          </Item>
        ))}
      </PoseGroup>
    </Wrapper>
  )
}


export default view(ToastContainer)
