import React from 'react'
import styled from 'styled-components'

import { useQuery } from '@apollo/react-hooks'
import { VALIDATE_CONFIRMATION_HASH_QUERY } from './queries'
import { useRouter, useIntl } from 'util/hooks'

import { RegisterUser } from 'modules/users'

import CenteredErrorMessage from 'components/CenteredErrorMessage'
import { CenteredLoader } from 'components/Loader'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;
`

function Activate() {
  const { match } = useRouter()
  const hash = match.params.hash

  const intl = useIntl()

  const { loading, error, data } = useQuery(VALIDATE_CONFIRMATION_HASH_QUERY, {
    variables: {
      hash,
    },
    skip: !hash,
  })

  if (!hash) {
    return <CenteredErrorMessage message="Confirmation hash not provided" />
  }

  if (loading) {
    return (
      <Wrapper>
        <CenteredLoader text={intl.fm('users.activating-account')} />
      </Wrapper>
    )
  }

  if (error || !data) {
    return <CenteredErrorMessage />
  }

  const {
    validateConfirmationHash: { ok, reason, user },
  } = data

  if (!ok) {
    if (reason === 'HASH_DOES_NOT_EXIST')
      return (
        <Wrapper>
          <CenteredErrorMessage message="Confirmation hash invalid" />
        </Wrapper>
      )
    else if (reason === 'HASH_EXPIRED')
      return (
        <Wrapper>
          <CenteredErrorMessage message="Hash is expired" />
        </Wrapper>
      )
    else return <CenteredErrorMessage />
  }

  return <RegisterUser user={user} />
}

Activate.propTypes = {}
Activate.defaultProps = {}

export default Activate
