import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function DetectClickOutside({
  onClickOutside,
  active,
  children,
  peripherals,
  detectEscape,

  ...rest
}) {
  const node = useRef(null)

  function handleClick(e) {
    if (!active) {
      return
    }

    // This is so weird. ESLint claims this variable (per) is not being used. Which it obviously is.
    // eslint-disable-next-line
    for (const per of peripherals) {
      if (per.current && per.current.contains(e.target)) {
        return
      }
    }

    if (node.current && node.current.contains(e.target)) {
      return
    }

    onClickOutside(e)
  }
  function handleKeyDown(e) {
    if (e.key.toLowerCase() === 'escape') {
      onClickOutside(e)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    document.addEventListener('touchstart', handleClick)
    if (detectEscape) document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
      if (detectEscape) document.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <div ref={node} {...rest}>
      {children}
    </div>
  )
}

DetectClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,

  active: PropTypes.bool,
  peripherals: PropTypes.arrayOf(PropTypes.shape({ current: PropTypes.any })),
  detectEscape: PropTypes.bool,
}
DetectClickOutside.defaultProps = {
  active: false,
  peripherals: [],
  detectEscape: false,
}

export default DetectClickOutside
