import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'

import { view } from 'react-easy-state'
import { store } from 'store'
import { useEvent, useEventListener, useIntl } from 'util/hooks'

import Icon from 'components/Icon'
import Sidebar from './Sidebar'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s linear;

  ${props =>
    props.shadowed &&
    `
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  `}

  h1 {
    font-size: 1rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.pinecone};
  }
`
const UserMenu = posed(styled.aside`
  position: absolute;
  top: 0;
  right: 0;

  background: ${props => props.theme.colors.defaultBackground};
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);

  div.inner {
    height: 100%;
    overflow-y: hidden;

    div.icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      padding-right: 1rem;
      height: 52px;

      ${props =>
        props.headerShadow &&
        `
        &::before {
          content: '';
          position: absolute;
          left: -4px;
          width: 6px;
          height: 52px;
          z-index: 2;
          background-color: ${props.theme.colors.defaultBackground};
        }
      `}
    }
    a {
      display: block;
      padding: 12px 14px;
      text-decoration: none;

      &:not(:first-of-type) {
        padding-top: 0px;
      }
    }
  }
`)({
  enter: {
    height: 'auto',
  },
  exit: {
    height: 52,
  },
})

function Header() {
  const intl = useIntl()
  const translations = {
    profile: intl.fm('common.profile'),
    logout: intl.fm('common.logout'),
  }

  const event = useEvent()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [shadowed, setShadowed] = useState(false)

  const onPageScroll = evt => {
    setShadowed(!!evt.target.scrollingElement.scrollTop)
  }
  useEventListener('scroll', onPageScroll)

  return (
    <Wrapper shadowed={store.headerShadowOverride || shadowed}>
      <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <Icon
        icon="bars"
        size="1.6rem"
        color="apple"
        onClick={() => setSidebarOpen(true)}
      />
      <h1>{event.name}</h1>
      <Icon
        solid
        icon="user"
        size="1.6rem"
        color="apple"
        onClick={() => setUserMenuOpen(true)}
      />
      <PoseGroup>
        {userMenuOpen && (
          <UserMenu
            key="userMenu"
            headerShadow={store.headerShadowOverride || shadowed}
          >
            <div className="inner">
              <div className="icon">
                <Icon
                  solid
                  icon="user"
                  size="1.6rem"
                  color="apple"
                  onClick={() => setUserMenuOpen(false)}
                />
              </div>
              <Link to="profile" onClick={() => setUserMenuOpen(false)}>
                {translations.profile}
              </Link>
              <Link to="logout" onClick={() => setUserMenuOpen(false)}>
                {translations.logout}
              </Link>
            </div>
          </UserMenu>
        )}
      </PoseGroup>
    </Wrapper>
  )
}

export default view(Header)
