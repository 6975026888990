import { DEBUG } from 'util/env'

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose'

import { useMutation } from '@apollo/react-hooks'
import { PATCH_USER_MUTATION, CHANGE_PASSWORD_MUTATION, DELETE_USER_MUTATION } from './mutations'
import { useUser, useIntl } from 'util/hooks'
import { toasts } from 'store'

import Input from 'components/Input'
import Button from 'components/Button'
import { removeLoginToken } from 'modules/login/util'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 1.6rem;

  h1 {
    margin: 0;
    font-size: 1.6rem;
  }
  p {
    margin: 1rem 0;
    font-size: 0.8rem;

    &.required {
      span {
        color: ${props => props.theme.colors.error}
      }
    }
  }
`
const Information = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
})
const DeleteUser = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
})
const FormWrapper = styled.div``
const FormField = styled.div`
  position: relative;

  &:not(:first-of-type) {
    margin-top: ${props => props.tall ? '1.8rem' : '0.8rem'};
  }
  ${props => (props.tall && !props.noDivider) && `
    &::before {
      content: '';
      position: absolute;
      top: -0.9rem;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 1px;
      background-color: ${props.theme.colors.primary};
    }
  `}
`

function Profile() {
  const user = useUser()

  const requiredRef = useRef(null)
  useEffect(() => {
    const el = requiredRef.current
    if (el) {
      const replaced = el.innerText.replace(/\*/g, '<span>*</span>')
      el.innerHTML = replaced
    }
  }, [requiredRef])

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [deletePassword, setDeletePassword] = useState('')

  const [errors, setErrors] = useState({})
  const [showDeleteUser, setShowDeleteUser] = useState(false)

  useEffect(() => {
    setDeletePassword('')
  }, [showDeleteUser])

  const intl = useIntl()
  const translations = {
    username: intl.fm('users.username'),
    firstName: intl.fm('users.first-name'),
    lastName: intl.fm('users.last-name'),
    phoneNumber: intl.fm('users.phone-number'),
    password: intl.fm('users.password'),
    oldPassword: intl.fm('users.old-password'),
    newPassword: intl.fm('users.new-password'),
    confirmPassword: intl.fm('users.confirm-password'),
    yourInformation: intl.fm('users.your-information'),
    fieldsRequired: intl.fm('common.fields-marked-are-required'),
    updateInformation: intl.fm('users.update-information'),
    updatePassword: intl.fm('users.update-password'),
    passwordInvalid: intl.fm('users.password-invalid'),
    invalid: intl.fm('common.invalid'),
    passwordsDoNotMatch: intl.fm('users.password-do-not-match'),
    userInfoUpdated: intl.fm('users.user-info-updated'),
    userInfoUpdateError: intl.fm('users.user-info-update-error'),
    userPasswordUpdated: intl.fm('users.password-updated'),
    userPasswordUpdateError: intl.fm('users.password-update-error'),
    userDeleteError: intl.fm('users.user-delete-error'),
    deleteUser: intl.fm('users.delete-user'),
    deleteUserText: intl.fm('users.delete-user-text'),
    userWasDeleted: intl.fm('users.user-was-deleted'),
    cancel: intl.fm('common.cancel')
  }

  const [patchUserMutation, { loading: patchUserLoading }] = useMutation(PATCH_USER_MUTATION, {
    refetchQueries: ['Me'],
    onCompleted() {
      toasts.addToast('success', translations.userInfoUpdated)
    },
    onError(error) {
      if (DEBUG) console.error(error)
      toasts.addToast('error', translations.userInfoUpdateError)
    }
  })
  const [changePasswordMutation, { loading: changePasswordLoading }] = useMutation(CHANGE_PASSWORD_MUTATION, {
    refetchQueries: ['Me'],
    onCompleted({ changePassword }) {
      if (changePassword.ok)
        toasts.addToast('success', translations.userPasswordUpdated)
      else
        setErrors({
          ...errors,
          oldPassword: translations.passwordInvalid
        })

      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
    },
    onError(error) {
      if (DEBUG) console.error(error)
      toasts.addToast('error', translations.userPasswordUpdateError)
    }
  })
  const [deleteUserMutation, { loading: deleteUserLoading }] = useMutation(DELETE_USER_MUTATION, {
    onCompleted({ deleteUser }) {
      setDeletePassword('')

      if (deleteUser.ok) {
        alert(translations.userWasDeleted)
        removeLoginToken()
        window.location.href = '/'
      } else
        setErrors({
          ...errors,
          deletePassword: translations.passwordInvalid
        })
    },
    onError(error) {
      if (DEBUG) console.error(error)
      toasts.addToast('error', translations.userDeleteError)
    }
  })

  const validateInformation = () => {
    const _errors = {
      firstName:  !!firstName ? null : translations.invalid,
      lastName:  !!lastName ? null : translations.invalid,
    }
    setErrors(_errors)
    return !Object.entries(_errors).some(([, val]) => val !== null)
  }
  const submitInformation = () => {
    if (!validateInformation()) return

    patchUserMutation({
      variables: {
        id: user.id,
        input: {
          firstName,
          lastName,
          phoneNumber
        }
      }
    })
  }
  const validatePassword = () => {
    const _errors = {
      oldPassword: !!oldPassword ? null : translations.invalid,
      newPassword: !!newPassword ? (newPassword !== confirmPassword ? translations.passwordsDoNotMatch : null) : translations.invalid,
      confirmPassword: !!confirmPassword ? (newPassword !== confirmPassword ? translations.passwordsDoNotMatch : null) : translations.invalid
    }
    setErrors(_errors)
    return !Object.entries(_errors).some(([, val]) => val !== null)
  }
  const submitPassword = () => {
    if (!validatePassword()) return

    changePasswordMutation({
      variables: {
        oldPassword,
        newPassword
      }
    })
  }
  const submitDelete = () => {
    deleteUserMutation({
      variables: {
        password: deletePassword
      }
    })
  }

  return (
    <Wrapper>
      <PoseGroup>
        {showDeleteUser ? (
          <DeleteUser key="delete">
            <FormWrapper>
              <h1>{translations.deleteUser}</h1>
              <p>{translations.deleteUserText}</p>

              <FormField tall noDivider>
                <Input
                  name="deletePassword"
                  type="password"
                  value={deletePassword}
                  error={errors.deletePassword}

                  fullWidth
                  required
                  autoCorrect="false"
                  autoComplete="none"
                  placeholder={translations.password}
                  disabled={deleteUserLoading}

                  onChange={evt => setDeletePassword(evt.target.value)}
                />
              </FormField>

              <FormField noDivider>
                <Button
                  type="button"

                  fullWidth
                  color="black"
                  backgroundColor="#cacaca"
                  disabled={deleteUserLoading}
                  isLoading={deleteUserLoading}

                  onClick={() => setShowDeleteUser(false)}
                >
                  {translations.cancel}
                </Button>
              </FormField>

              <FormField noDivider>
                <Button
                  type="button"

                  fullWidth
                  color="white"
                  backgroundColor="error"
                  disabled={deleteUserLoading}
                  isLoading={deleteUserLoading}

                  onClick={submitDelete}
                >
                  {translations.deleteUser}
                </Button>
              </FormField>
            </FormWrapper>
          </DeleteUser>
        ) : (
          <Information key="information">

            <h1>{translations.yourInformation}</h1>
            <p className="required" ref={requiredRef}>{translations.fieldsRequired}</p>

            <FormWrapper>
              <FormField>
                <Input
                  name="oldPassword"
                  type="password"
                  value={oldPassword}
                  error={errors.oldPassword}

                  fullWidth
                  required
                  autoCorrect="false"
                  autoComplete="none"
                  placeholder={translations.oldPassword}
                  disabled={changePasswordLoading}

                  onChange={evt => setOldPassword(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  error={errors.newPassword}

                  fullWidth
                  required
                  autoCorrect="false"
                  autoComplete="none"
                  placeholder={translations.newPassword}
                  disabled={changePasswordLoading}

                  onChange={evt => setNewPassword(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  error={errors.confirmPassword}

                  fullWidth
                  required
                  autoCorrect="false"
                  autoComplete="none"
                  placeholder={translations.confirmPassword}
                  disabled={changePasswordLoading}

                  onChange={evt => setConfirmPassword(evt.target.value)}
                />
              </FormField>

              <FormField noDivider>
                <Button
                  type="button"

                  primary
                  fullWidth
                  disabled={changePasswordLoading}
                  isLoading={changePasswordLoading}

                  onClick={submitPassword}
                >
                  {translations.updatePassword}
                </Button>
              </FormField>

              <FormField tall>
                <Input
                  name="firstName"
                  value={firstName}
                  error={errors.firstName}

                  required
                  fullWidth
                  placeholder={translations.firstName}
                  disabled={patchUserLoading}

                  onChange={evt => setFirstName(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="lastName"
                  value={lastName}
                  error={errors.lastName}

                  required
                  fullWidth
                  placeholder={translations.lastName}
                  disabled={patchUserLoading}

                  onChange={evt => setLastName(evt.target.value)}
                />
              </FormField>

              <FormField>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}

                  fullWidth
                  placeholder={translations.phoneNumber}
                  inputMode="tel"
                  disabled={patchUserLoading}

                  onChange={evt => setPhoneNumber(evt.target.value)}
                />
              </FormField>

              <FormField noDivider>
                <Button
                  type="button"

                  primary
                  fullWidth
                  disabled={patchUserLoading}
                  isLoading={patchUserLoading}

                  onClick={submitInformation}
                >
                  {translations.updateInformation}
                </Button>
              </FormField>


              <FormField tall>
                <Button
                  type="button"

                  fullWidth
                  color="white"
                  backgroundColor="error"
                  disabled={deleteUserLoading}
                  isLoading={deleteUserLoading}

                  onClick={() => setShowDeleteUser(true)}
                >
                  {translations.deleteUser}
                </Button>
              </FormField>
            </FormWrapper>
          </Information>
        )}
      </PoseGroup>
    </Wrapper>
  )
}

Profile.propTypes = { }
Profile.defaultProps = { }

export default Profile
