import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'

import { useIntl, useEvent } from 'util/hooks'

const Wrapper = posed(styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: 100vh;

  background-color: ${props => props.theme.colors.defaultBackground};
  box-shadow: 2px 0 3px 1px rgba(0, 0, 0, 0.1);
`)({
  enter: {
    x: 0,
    transition: { type: 'tween' }
  },
  exit: {
    x: '-120%',
    transition: { type: 'tween' },
  }
})
const Shade = posed(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`)({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0
  }
})

const Menu = posed(styled.ul`
  list-style: none;
  margin: 52px 0 0 0;
  padding: 0;
  box-sizing: border-box;

  .divider {
    box-sizing: border-box;
    width: calc(100% - 2rem);
    height: 1px;
    margin: 0.6rem 0 0.6rem 1rem;
    background-color: ${props => props.theme.colors.greensmoke};
  }
`)({
  enter: {
    staggerChildren: 100,
  },
  exit: {
    staggerChildren: 100,
    staggerDirection: -1,
    afterChildren: true
  }
})
const MenuItem = posed(styled.li`
  a {
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0.8rem 3rem 0.8rem 0;
    margin-left: 2rem;

    text-decoration: none;

    &.active::before {
      content: '\f105';
      position: absolute;
      top: 2px;
      left: -6px;
      transform: translateX(-100%);
      display: flex;
      align-items: center;
      height: 100%;

      font-family: 'Font Awesome 5 Pro';
      font-size: 0.8rem;
    }
  }
`)({
  enter: {
    x: 0,
    transition: { type: 'tween' }
  },
  exit: {
    x: '-100%',
    transition: { type: 'tween' }
  }
})

function Sidebar({ open, onClose }) {
  const { name, organizer } = useEvent()

  const intl = useIntl()
  const translations = {
    places: intl.fm('common.places'),
    map: intl.fm('common.map'),
    prices: intl.fm('common.prices'),
    help: intl.fm('common.help'),
    aboutOrganizer: intl.fm('events.about-organizer', null, { organizer: organizer.name })
  }

  return (
    <PoseGroup>
      {open && [
        <Shade key="shade" onClick={onClose}/>,
        <Wrapper key="sidebar">
          <Menu>
            <MenuItem>
              <NavLink to="/dashboard" onClick={onClose}>
                {name}
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/map" onClick={onClose}>
                {translations.map}
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/points" onClick={onClose}>
                {translations.places}
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/rewards" onClick={onClose}>
                {translations.prices}
              </NavLink>
            </MenuItem>

            <li className="divider"></li>

            <MenuItem>
              <NavLink to="/help" onClick={onClose}>
                {translations.help}
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/about" onClick={onClose}>
                {translations.aboutOrganizer}
              </NavLink>
            </MenuItem>
          </Menu>
        </Wrapper>
      ]}
    </PoseGroup>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
Sidebar.defaultProps = { }

export default Sidebar
