import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useUser, useEvent, useIntl } from 'util/hooks'

import ControlPointPopup from './ControlPointPopup'

const Wrapper = styled.div`
  h2 {
    margin: 6px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    color: ${props => props.theme.colors.pinecone};
  }
`
const List = styled.dl`
  margin: 0;
  padding: 0;

  dt {
    position: sticky;
    top: 52px;

    background: ${props => props.theme.colors.americano};
    color: ${props => props.theme.colors.desertstorm};
    margin: 0;
    padding: 2px 0 2px 12px;
  }

  dd {
    margin: 0;
    padding: 6px 12px 6px 12px;
    white-space: nowrap;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & + dd {
      border-top: 1px solid #ccc;
    }
    &:active {
      background-color: #cfc5be;
    }

    span.visit {
      margin-left: 1ch;
      font-size: 0.6rem;
      text-transform: uppercase;
      color: ${props => props.theme.colors.error};

      &.visited {
        color: ${props => props.theme.colors.success};
      }
    }
  }
`

function ControlPoints() {
  const user = useUser()
  const event = useEvent()

  const [popupOpen, setPopupOpen] = useState(false)
  const [popupControlPoint, setPopupControlPoint] = useState(null)
  const [sortedControlPoints, setSortedControlPoints] = useState({})

  useEffect(() => {
    let _unsorted = event.controlPoints.edges.map(({ node }) => node)
    _unsorted = _unsorted.reduce((acc, point) => {
      const nameUpper = point.name.toUpperCase()
      const char = nameUpper.charAt(0)

      if (!acc.hasOwnProperty(char)) acc[char] = []

      acc[char].push(point)
      return acc
    }, {})

    const _sorted = {}
    Object.keys(_unsorted)
      .sort()
      .forEach(key => {
        _sorted[key] = _unsorted[key]
      })

    setSortedControlPoints(_sorted)

    if (popupControlPoint) {
      const newCP = event.controlPoints.edges.find(
        ({ node }) => node.id === popupControlPoint.id
      )
      if (newCP) setPopupControlPoint(newCP.node)
    }
  }, [event, popupControlPoint])

  const intl = useIntl()
  const translations = {
    places: intl.fm('points.places'),
    visited: intl.fm('points.visited'),
    notVisited: intl.fm('points.not-visited'),
  }

  const hasVisited = cp => {
    const _visitor = cp.visitors.edges.find(
      ({ node: { user: visitor } }) => visitor.id === user.id
    )
    return _visitor && _visitor.node.visitedAt
  }

  const popup = cp => {
    setPopupControlPoint(cp)
    setPopupOpen(true)
  }

  return (
    <>
      <ControlPointPopup
        open={popupOpen}
        controlPoint={popupControlPoint}
        findInMapButton
        onClose={() => setPopupOpen(false)}
      />
      <Wrapper>
        <h2>{translations.places}</h2>
        <List>
          {Object.entries(sortedControlPoints).map(([char, points]) => (
            <React.Fragment key={char}>
              <dt>{char}</dt>
              {points.map(point => {
                const _visited = hasVisited(point)
                return (
                  <dd key={point.id} onClick={() => popup(point)}>
                    {point.name}
                    <span className={`visit ${_visited && 'visited'}`}>
                      {_visited
                        ? translations.visited
                        : translations.notVisited}
                    </span>
                  </dd>
                )
              })}
            </React.Fragment>
          ))}
        </List>
      </Wrapper>
    </>
  )
}

ControlPoints.propTypes = {}
ControlPoints.defaultProps = {}

export default ControlPoints
