import moment from 'moment'

// Set up moment localisation
moment.locale('nb', {
  months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split(
    '_'
  ),
  monthsShort: 'jan._feb._mars_apr._mai_juni_juli_aug_sep._okt._nov._des.'.split(
    '_'
  ),
  monthsParseExact: true,
  weekdays: 'mandag_tirsdag_onsdag_torsdag_fredag_lørdag_søndag'.split('_'),
  weekdaysShort: 'man_tir_ons_tor_fre_lør_søn'.split('_'),
  weekdaysMin: 'Ma_Ti_On_To_Fr_Lø_Sø'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D[.] MMMM YYYY',
    LLL: 'D[.] MMMM YYYY HH:mm',
    LLLL: 'dddd D[.] MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[I dag klokken] LT',
    nextDay: '[I morgen klokken] LT',
    nextWeek: '[sist] dddd [klokken] LT',
    lastDay: '[I går klokken] LT',
    lastWeek: '[forrige] dddd [klokken] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'om %s',
    past: '%s siden',
    s: 'noen sekunder',
    m: 'ett minutt',
    mm: '%d minutter',
    h: 'en time',
    hh: '%d timer',
    d: 'en dag',
    dd: '%d dager',
    M: 'en måned',
    MM: '%d måneder',
    y: 'ett år',
    yy: '%d år',
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
})
