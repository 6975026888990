import { DEBUG } from 'util/env'

import React, { useState } from 'react'
import styled from 'styled-components'

import { useMutation } from '@apollo/react-hooks'
import { RESET_PASSWORD_MUTATION } from './mutations'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'util/hooks'
import { toasts } from 'store'
import { Link } from 'react-router-dom'

import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 1rem;

  a.login {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    color: ${props => props.theme.colors.killarney};

    span {
      margin-right: 1ch;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }
  p {
    text-align: center;
  }
`
const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'header'
    'text'
    'email'
    'submit';
  grid-gap: 12px;

  h2 {
    grid-area: header;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  p {
    grid-area: text;
    margin: 0 0 8px;
    text-align: center;
  }
`
const Email = styled.div`
  grid-area: email;
`
const SubmitButton = styled(Button)`
  grid-area: submit;
`

function ForgottenPassword() {
  const [email, setEmail] = useState('')
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)

  const intl = useIntl()
  const translations = {
    email: intl.fm('common.email'),
    resetPasswordError: intl.fm('login.reset-password-error'),
    resetPasswordSuccessPrompt: intl.fm('login.reset-password-success-prompt'),
    signIn: intl.fm('login.sign-in'),
  }

  const [resetPasswordMutation, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted(data) {
      const { resetPassword: { ok }} = data
      if (!ok) {
        toasts.addToast('error', translations.resetPasswordError)
      } else {
        setResetPasswordSuccess(true)
      }
    },
    onError(err) {
      if (DEBUG) console.error(err)
      toasts.addToast('error', translations.resetPasswordError)
    }
  })

  const submit = () => {
    if (!email) {
      return
    }
    resetPasswordMutation({
      variables: {
        email
      }
    })
  }

  return (
    <Wrapper>
      <Link className="login" to="/login">
        <span>{translations.signIn}</span>
        <Icon icon="user" />
      </Link>
      {resetPasswordSuccess ? (
        <p>{translations.resetPasswordSuccessPrompt}</p>
      ) : (
        <FormWrapper>
          <h2>
            <FormattedMessage id="login.forgot-password-title" defaultMessage="Forgotten password" />
          </h2>
          <p>
            <FormattedMessage id="login.forgot-password-prompt" defaultMessage="Please provide your email address, and we'll send you a new password." />
          </p>
          <Email>
            <Input
              value={email}
              type="text"

              inputMode="email"
              required
              fullWidth
              placeholder={translations.email}
              autoCorrect="false"
              autoCapitalize="none"
              disabled={loading}

              onChange={evt => setEmail(evt.target.value)}
            />
          </Email>

          <SubmitButton
            primary
            fullWidth
            disabled={!email || loading}

            isLoading={loading}
            loadingIntlId="login.send-new-password"

            onClick={submit}
          >
            <FormattedMessage id="login.send-new-password" defaultMessage="Send new password" />
          </SubmitButton>
        </FormWrapper>
      )}
    </Wrapper>
  )
}

ForgottenPassword.propTypes = { }
ForgottenPassword.defaultProps = { }

export default ForgottenPassword
