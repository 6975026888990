import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet'
import moment from 'moment'
import { useEvent, useIntl, useRouter } from 'util/hooks'
import { htmlifyUrls } from 'util/helpers'
import { getFirstLogin, removeFirstLogin } from 'modules/login/util'

import FirstLoginPopup from './FirstLoginPopup'
import UrFooter from 'components/UrFooter/index'

const strike = (width, height, background) => css`
  position: absolute;
  z-index: -1;
  top: calc(50% - (${height} / 2));
  left: 0;
  width: ${width};
  height: ${height};
  background: ${background};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
`
const Cover = styled.div`
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`
const Info = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'logo  name'
    'logo  organizer'
    'when  when'
    'where where'
    'desc  desc';
  grid-column-gap: 8px;

  padding: 1rem;

  div.logo {
    grid-area: logo;

    img {
      display: block;
      height: 48px;
      object-fit: contain;
    }
  }
  h2 {
    grid-area: name;
    align-self: end;
    margin: 0 0 0 4px;
  }
  span.organizer {
    grid-area: organizer;
    margin: 0 0 0 4px;
    font-size: 0.6rem;
    font-weight: 600;
    color: ${props => props.theme.colors.americano};
  }
  div.when {
    grid-area: when;
    margin: 18px 0 4px;
  }
  div.where {
    grid-area: where;
    margin: 12px 0 4px;
  }
  div.desc {
    grid-area: desc;
    margin: 12px 0 4px;
  }
  h3 {
    position: relative;
    z-index: 2;
    margin: 0 0 4px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding-left: 3em;

    &::before {
      content: '';
      ${props => strike('100%', '2px', props.theme.colors.americano)}
    }
    span {
      padding: 0 12px;
      background: ${props => props.theme.colors.defaultBackground};
    }
  }
  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1ex;

    font-size: 0.8rem;
    margin: 0;

    dt,
    dd {
      margin: 0;
      align-self: end;
    }
    dt {
      text-align: right;
      transform: translateY(-1px);
      font-size: 0.6rem;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.colors.americano};
    }
  }
  p {
    margin: 0;
    font-size: 0.8rem;
    white-space: pre-wrap;
  }
`
const MapWrapper = styled.div`
  position: relative;
  margin-top: 0.6rem;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 120px;
  }

  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 502;

    display: flex;
    justify-content: center;
    align-items: center;

    text-transform: uppercase;
    font-weight: 900;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.6);
    filter: drop-shadow(0px 0px 12px ${props => props.theme.colors.apple});
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;

    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 501;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`

const SectionHead = ({ children }) => (
  <h3>
    <span>{children}</span>
  </h3>
)

function Dashboard() {
  const initialMount = useRef(true)

  const event = useEvent()
  const { history } = useRouter()

  const [showFirstLoginPopup, setShowFirstLoginPopup] = useState(false)
  const [initialCoordinates, setInitialCoordinates] = useState([
    59.9133861,
    10.751924,
  ])

  useEffect(() => {
    if (!initialMount.current) return
    initialMount.current = false

    setShowFirstLoginPopup(getFirstLogin())
    removeFirstLogin()

    if (event.latitude !== null && event.longitude !== null)
      setInitialCoordinates([event.latitude, event.longitude])
  }, [event.latitude, event.longitude])

  const intl = useIntl()
  const translations = {
    organizer: organizer => intl.fm('events.organizer', null, { organizer }),
    when: intl.fm('common.when'),
    where: intl.fm('common.where'),
    location: intl.fm('common.location'),
    starts: intl.fm('events.starts'),
    ends: intl.fm('events.ends'),
    description: intl.fm('common.description'),
    goToMap: intl.fm('map.go-to-map'),
  }

  const hasLocation = !!event.location

  return (
    <Wrapper>
      <FirstLoginPopup
        show={showFirstLoginPopup}
        onClose={() => setShowFirstLoginPopup(false)}
      />
      <Cover role="link" onClick={() => history.push('/map')}>
        <img src={event.cover} alt="Cover" />
      </Cover>

      <Info>
        <div className="logo">
          <img src={event.logo} alt="Logo" />
        </div>
        <h2>{event.name}</h2>
        {event.organizer && (
          <span className="organizer">
            {translations.organizer(event.organizer.name)}
          </span>
        )}

        <div className="when">
          <SectionHead>{translations.when}</SectionHead>

          <dl>
            <dt>{translations.starts}</dt>
            <dd>{moment(event.dateStart).format('LLL')}</dd>

            <dt>{translations.ends}</dt>
            <dd>{moment(event.dateEnd).format('LLL')}</dd>
          </dl>
        </div>

        {hasLocation && (
          <div className="where">
            <SectionHead>{translations.where}</SectionHead>

            <dl>
              <dt>{translations.location}</dt>
              <dd>{event.location}</dd>
            </dl>

            <MapWrapper>
              <div
                className="overlay"
                role="link"
                onClick={() => history.push('/map')}
              >
                {translations.goToMap}
              </div>
              <LeafletMap
                center={initialCoordinates}
                zoom={11}
                maxZoom={11}
                attributionControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                dragging={false}
                animate={true}
                zoomControl={false}
                easeLinearity={0.35}
              >
                <TileLayer
                  url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>'
                />
                <Marker position={initialCoordinates} />
              </LeafletMap>
            </MapWrapper>
          </div>
        )}

        <div className="desc">
          <SectionHead>{translations.description}</SectionHead>

          <p dangerouslySetInnerHTML={htmlifyUrls(event.description)} />
        </div>
      </Info>

      <UrFooter />
    </Wrapper>
  )
}

export default Dashboard
