import gql from 'graphql-tag'
import { ControlPointFragments } from 'modules/points/fragments'

export const EventFragments = {
  info: gql`
    fragment EventInfo on EventNode {
      id
      name
      shortName
      organizer {
        id
        name
        description
        image
        homepage
        email
        phoneNumber
      }
      description
      cover
      logo
      significantControlPointIcon
      significantControlPointText
      dateStart
      dateEnd
      location
      longitude
      latitude
      participating
      forfeitedAt
      pointVisitMethods
      controlPoints {
        edges {
          node {
            ...ControlPointInfo
          }
        }
      }
      rewards {
        edges {
          node {
            id
            active
            name
            description
            image
            priority
          }
        }
      }
      eventSettings {
        edges {
          node {
            id
            key
            value
          }
        }
      }
    }
    ${ControlPointFragments.info}
  `,
}
