import Login from './Login'
import Logout from './Logout'
import ForgottenPassword from './ForgottenPassword'
import Register from './Register'
import Activate from './Activate'

export {
  Logout,
  ForgottenPassword,
  Register,
  Activate
}
export default Login
