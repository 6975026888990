import gql from 'graphql-tag'
import { UserFragments } from 'modules/users/fragments'

export const IS_LOGGED_IN_QUERY = gql`
  query IsLoggedIn {
    isLoggedIn
  }
`

export const VALIDATE_CONFIRMATION_HASH_QUERY = gql`
  query ValidateConfirmationHash($hash: String!) {
    validateConfirmationHash(hash: $hash) {
      ok
      reason
      user {
        ...UserInfo
      }
    }
  }
  ${UserFragments.info}
`

export const RESEND_CONFIRMATION_HASH_QUERY = gql`
  query ResendConfirmationHash {
    resendConfirmationHash {
      ok
      user {
        ...UserInfo
      }
    }
  }
  ${UserFragments.info}
`
