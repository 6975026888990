import { DEBUG } from 'util/env'

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import posed from 'react-pose'

import { useMutation } from '@apollo/react-hooks'
import { CREATE_EVENT_SIGNUP_MUTATION } from './mutations'
import moment from 'moment'
import { toasts } from 'store'
import { useIntl } from 'util/hooks'
import { buttonUnset } from 'util/style'

import Icon from 'components/Icon'
import Loader from 'components/Loader'

const Wrapper = posed(styled.section`
  height: 160px;

  position: relative;
  box-sizing: border-box;
  margin-bottom: 1.6rem;

  border-radius: 12px;
  background-color: white;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3);

  transform-style: preserve-3d;

  h3 {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 4px 0;
    font-size: 1rem;
    text-align: center;

    &::before {
      content: '';
      ${props => strike('45px', '2px', `linear-gradient(to right, transparent, ${props.theme.colors.pinecone})`)}
      transform: translateX(-120%);
    }
    &::after {
      content: '';
      ${props => strike('45px', '2px', `linear-gradient(to left, transparent, ${props.theme.colors.pinecone})`)}
      transform: translateX(20%);
    }
  }
`)({
  enter: { y: 0, opacity: 1 },
  exit: { y: 500, opacity: 0 },
  flipped: {
    rotateY: 179,
    transition: { duration: 550 }
  },
  unflipped: {
    rotateY: 0,
    transition: { duration: 550 }
  }
})
const cardFace = css`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
`
const strike = (width, height, background) => css`
  position: absolute;
  z-index: 1;
  top: calc(50% - (${height} / 2));
  width: ${width};
  height: ${height};
  background: ${background};
`
const Cover = styled.div`
  ${cardFace}

  border-radius: 12px;
  overflow: hidden;
  ${props => props.image ? `
    background-image: url('${props.image}');
    background-size: cover;
  ` : `
    background:
    linear-gradient(135deg, #708090 21px, #d9ecff 22px, #d9ecff 24px, transparent 24px, transparent 67px, #d9ecff 67px, #d9ecff 69px, transparent 69px),
    linear-gradient(225deg, #708090 21px, #d9ecff 22px, #d9ecff 24px, transparent 24px, transparent 67px, #d9ecff 67px, #d9ecff 69px, transparent 69px)0 64px;
    background-color:#708090;
    background-size: 64px 128px;
  `}
`
const CoverOverlay = styled.div`
  backface-visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: 4px;
  transform: translateX(-50%);
  width: calc(100% - 8px);

  display: grid;
  grid-template-columns: 42px 1fr 42px;
  grid-auto-rows: auto;
  grid-template-areas: 'logo info more';
  opacity: 1;
  transition: opacity 0.2s ease-in;

  ${props => props.flipped && `
    pointer-events: none;
    opacity: 0;
  `}

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    box-shadow: inset 0 0 3px 1px rgba(110, 98, 89, 0.6);
    border-radius: 2px 2px 12px 12px;
    background-color: rgba(244, 243, 242, 1);
  }

  div.info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    div.when {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 8px;

      span.date {
        font-size: 0.6rem;

        .label {
          color: ${props => props.theme.colors.americano};
          font-weight: 600;
          text-transform: uppercase;
          margin-right: 1ch;
        }
      }
    }
  }
  i {
    grid-area: more;
    align-self: center;
    padding-right: 6px;
  }
`

const Backface = styled.div`
  ${cardFace}
  transform: rotateY(180deg);

  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 { margin-top: 12px; }
  p {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 4px 24px 12px;
    font-size: 0.8rem;
    text-align: center;

    overflow-y: auto;
  }
  div.buttons {
    display: flex;
    justify-content: center;

    button {
      ${buttonUnset}

      margin-bottom: 12px;
      height: 32px;
      padding: 0 32px;
      border: 1px solid ${props => props.theme.colors.pinecone};
      font-size: 0.6rem;
      font-weight: 600;
      text-transform: uppercase;

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: ${props => props.theme.colors.ebb};
      }
      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${props => props.theme.colors.primary};
        color: white;
      }
      &:not(:first-of-type) {
        border-left: none;
      }
      &:active {
        filter: brightness(0.8);
      }
    }
  }
`
const SignupLoader = styled.div`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 123;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.8);
`

function EventCard({
  event,
  userEvent
}) {
  const [flipped, setFlipped] = useState(false)
  const [showSignup, setShowSignup] = useState(false)

  const [createEventSignupMutation, { loading: createEventSignupLoading }] = useMutation(CREATE_EVENT_SIGNUP_MUTATION, {
    refetchQueries: ['AllEvents'],
    awaitRefetchQueries: true,
    onCompleted() {
      toasts.addToast('success', translations.signedUpForEvent)
      window.location.href = `/e/${event.shortName}`
    },
    onError(err) {
      if (DEBUG) console.error(err)
      toasts.addToast('error', translations.serverError)
    }
  })

  const intl = useIntl()
  const translations = {
    loading: intl.fm('events.loading'),
    starts: intl.fm('events.starts'),
    ends: intl.fm('events.ends'),
    cancel: intl.fm('common.cancel'),
    signup: intl.fm('events.sign-up'),
    signupPrompt: intl.fm('events.sign-up-prompt'),
    signedUpForEvent: intl.fm('events.signed-up-for-event', null, { name: event.name }),
    serverError: intl.fm('server.general-error-try-again-later'),
  }

  const unflip = evt => {
    evt.stopPropagation()
    setFlipped(false)

    setTimeout(() => setShowSignup(false), 500)
  }
  const eventClickHandler = () => {
    if (userEvent)
      window.location.href = `/e/${event.shortName}`
    else {
      setShowSignup(true)
      setFlipped(true)
    }
  }
  const joinEvent = () => {
    createEventSignupMutation({
      variables: {
        event: event.id
      }
    })
  }

  return (
    <Wrapper
      pose={flipped ? 'flipped' : 'unflipped'}
    >
      <Cover
        image={event.cover}
        onClick={eventClickHandler}
      >
        <CoverOverlay flipped={flipped}>
          <div className="info">
            <h3>{event.name}</h3>
            <div className="when">
              <span className="date">
                <span className="label">
                  {translations.starts}
                </span>
                {moment(event.dateStart).format('LL LT')}
              </span>
              <span className="date">
                <span className="label">
                  {translations.ends}
                </span>
                {moment(event.dateEnd).format('LL LT')}
              </span>
            </div>
          </div>
          <Icon
            solid
            icon="question-circle"
            size="2rem"
            color="pinecone"

            onClick={evt => { evt.stopPropagation(); setFlipped(true) }}
          />
        </CoverOverlay>
      </Cover>
      {showSignup ? (
        <Backface>
          {createEventSignupLoading && (
            <SignupLoader>
              <Loader />
            </SignupLoader>
          )}
          <h3>{event.name}</h3>
          <p>{translations.signupPrompt}</p>
          <div className="buttons">
            <button type="button" onClick={unflip}>
              {translations.cancel}
            </button>
            <button type="button" onClick={joinEvent}>
              {translations.signup}
            </button>
          </div>
        </Backface>
      ) : (
        <Backface onClick={unflip}>
          <h3>{event.name}</h3>
          <p>{event.description}</p>
        </Backface>
      )}
    </Wrapper>
  )
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  userEvent: PropTypes.bool
}
EventCard.defaultProps = {
  userEvent: false
}

export default EventCard
